import {
  RegistrationEnum,
  LoginEnum,
  UpdateProfileEnum,
  DeleteProfileEnum,
  RecoverPasswordEnum,
} from 'shared/models/enums'
import {
  ERROR_UNKNOW_ERROR,
  ERROR_USER_EXISTS,
  ERROR_NO_USER_FOUND,
  ERROR_MULTIPLE_USERS,
  ERROR_CONFIRMATION_ALREADY_SENT,
  ERROR_CREATING_USER,
  ERROR_USER_DOES_NOT_EXIST,
  ERROR_INVALID_RETURN_URL,
  ERROR_WRONG_USERNAME_OR_PASSWORD,
  ERROR_EMAIL_INVALID,
  ERROR_INVALID_PASSWORD_FORMAT,
  ERROR_PHONE_NUMBER_USED,
  ERROR_EMAIL_INUSE,
  ERROR_DELETE_USER_FAILED,
  ERROR_DELETE_USER_WRONG_PASSWORD,
  ERROR_GENERAL_ERROR,
  ERROR_WRONG_ONE_TIME_CODE,
  ERROR_UNKNOWN,
  ERROR_PHONE_ALREADY_IN_USE,
  ERROR_EMAIL_ALREADY_IN_USE,
} from 'shared/constants/errorMessages'
import { PASSWORD_RULE } from 'shared/constants/messages'

export const getRegistrationErrorMessage = (code: RegistrationEnum) => {
  switch (code) {
    case RegistrationEnum.UnknownAuthenticationMethod:
      return ERROR_UNKNOW_ERROR
    case RegistrationEnum.UserExistWithConfirmedEmailOrPhone:
      return ERROR_USER_EXISTS
    case RegistrationEnum.MoreThanOneResult:
      return ERROR_MULTIPLE_USERS
    case RegistrationEnum.ConfirmationCodeSent:
      return ERROR_CONFIRMATION_ALREADY_SENT
    case RegistrationEnum.FailedCreatingUser:
      return ERROR_CREATING_USER
    case RegistrationEnum.NoUserFound:
      return ERROR_NO_USER_FOUND
    case RegistrationEnum.WrongConfirmationCode:
      return ERROR_WRONG_ONE_TIME_CODE
    case RegistrationEnum.NotValidPasswordFormat:
      return ERROR_INVALID_PASSWORD_FORMAT
    case RegistrationEnum.PhoneNumberIsUsed:
      return ERROR_PHONE_NUMBER_USED
    case RegistrationEnum.EmailIsUsed:
      return ERROR_EMAIL_INUSE
    default:
      return ERROR_GENERAL_ERROR
  }
}

export const getLoginErrorMessage = (code: LoginEnum) => {
  switch (code) {
    case LoginEnum.UserDoesNotExist:
      return ERROR_USER_DOES_NOT_EXIST
    case LoginEnum.InvalidReturnUrl:
      return ERROR_INVALID_RETURN_URL
    case LoginEnum.WrongUsernameOrPassword:
      return ERROR_WRONG_USERNAME_OR_PASSWORD
    default:
      return ERROR_GENERAL_ERROR
  }
}

export const getUpdateProfileErrorMessage = (code: UpdateProfileEnum) => {
  switch (code) {
    case UpdateProfileEnum.FailedValidation:
      return ERROR_EMAIL_INVALID
    case UpdateProfileEnum.PhoneAlreadyInUse:
      return ERROR_PHONE_ALREADY_IN_USE
    case UpdateProfileEnum.EmailAlreadyInUse:
      return ERROR_EMAIL_ALREADY_IN_USE
    default:
      return ERROR_GENERAL_ERROR
  }
}

export const getDeleteProfileErrorMessage = (code: DeleteProfileEnum) => {
  switch (code) {
    case DeleteProfileEnum.FailedDeletingUser:
      return ERROR_DELETE_USER_FAILED
    case DeleteProfileEnum.WrongPassword:
      return ERROR_DELETE_USER_WRONG_PASSWORD
    default:
      return ERROR_GENERAL_ERROR
  }
}

export const getForgotPasswordErrorMessage = (code: RecoverPasswordEnum) => {
  switch (code) {
    case RecoverPasswordEnum.InvalidPasswordFormat:
      return `${ERROR_INVALID_PASSWORD_FORMAT} ${PASSWORD_RULE}`
    case RecoverPasswordEnum.UserDoesNotExist:
      return ERROR_NO_USER_FOUND
    case RecoverPasswordEnum.WrongOneTimeCode:
      return ERROR_WRONG_ONE_TIME_CODE
    default:
      return ERROR_UNKNOWN
  }
}
