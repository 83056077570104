import { createRoot } from 'react-dom/client'
import { handleError } from 'shared/utils/error'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (window.location.pathname.indexOf('vippsError') === -1) {
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(<App />)
} else {
  handleError()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

declare global {
  interface Window {
    config: {
      bblCode: string
      baseUrl: string
      bblidBaseUrl: string
      bblidGatewayUrl: string
      instrumentationKey: string
      aiCloudRole: string
      bblPivotalGtmCode: string
      clientId: string
      themeUrl: string
    }
    grecaptcha: any
  }
}

