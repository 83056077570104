import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
`
export const Hidden = styled.button`
  visibility: hidden;
`

export const InputWrapper = styled.div``

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px - 159px - 48px - 32px);
`

export const FormWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  justify-items: center;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin: 0;
  }
`

export const ActionWrapper = styled.div`
  width: 100%;
  max-width: 320px;

  & > button {
    height: 48px;
    border-radius: 9999px;
    padding: 1em 2em;
    z-index: 400;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 600;
  }

  & > span {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  & > button.vipps {
    width: 100%;
    padding: 0;
    height: 46px;
    border-radius: 9999px;
    background: #ff5b24;
    border-color: #ff5b24;

    &:hover {
      background: #ff5b24;
      border-color: #ff5b24;
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > button.userpw {
    margin-top: 15px;
    font-weight: bold;
    padding: 0;

    z-index: 400;
    font-weight: 600;
  }
`

export const DatepickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  margin-bottom: 1em;

  & > div {
    z-index: 1000;
    justify-content: center;

    span {
      text-align: center;
    }
    input {
      height: 40px;
      padding: 0.5em;
      text-align: center;
    }
  }
`

export const KnutWrapper = styled.div`
  width: 100%;
  max-width: 320px;

  & > label {
    margin: .5em 0;
    input {
      width: unset;
    }
    span {
      padding-top: 5px;
      padding-left: 25px;
    }
    input {
      padding: 0.5em 0.5em;
      background: white;
      height: 40px;
      text-align: center;
      font-size: 1.5em;
    }
  }
`

export const KnutWrapper2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  position: relative;
  margin: 10px 0;

  input {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    height: 0;
    width: 0;
  }

  input:focus + div {
  }
`

export const DigitBox = styled.div(
  (props: any) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    border: 1px solid #cfd9ea;
    padding: 8px;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 5px;
    border-color: ${props.focus ? props.primary : props.borderColor};
  `
)

export const VippsImage = styled.img`
  height: 40px;
`

export const PageLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .7);
`

export const errorStyle = css`
  max-width: 330px;
`