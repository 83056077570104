export const API_GET_USER = 'api/user'
export const API_GET_BBL = 'api/bbl'
export const API_GET_USER_BBLS = 'api/user/bbls'
export const API_DELETE_USER = 'api/user'

export const API_CREATE_USER = '/api/user/new/step1'
export const API_CONFIRM_USER = '/api/user/new/step2'
export const API_VERIFY_USER = '/api/user/new/step3'

export const API_UPDATE_IMAGE = '/api/profile/image'
export const API_DELETE_IMAGE = '/api/profile/image'
export const API_UPDATE_PROFILE = '/api/profile/update'
export const API_UPDATE_PROFILE_WITH_CODE = '/api/profile/update?code='
export const API_UPDATE_PASSWORD = '/api/profile/password'

export const API_RECOVER_PASSORD = '/api/user/recoverpassword'

export const API_INITIATE_RECOVER_PASSWORD = '/api/user/initiaterecoverpassword'

export const API_SEND_REQUEST = '/api/user/request'
