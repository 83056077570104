import styled from '@emotion/styled'
import bp from '@bbl-digital/snorre/lib/globals/styles/breakpoints'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 480px;
  grid-column-gap: 2em;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 48px;

  & > div:nth-of-type(2) {
    overflow: visible;
  }

  @media screen and (max-width: ${bp.small}) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
    margin-top: 0;
  }
`
