import React, { useState, Fragment, useContext, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { Link, Alert } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { useHistory } from 'react-router-dom'
import Create from './Create'
import { RegistrationEnum } from 'shared/models/enums'
import { getRegistrationErrorMessage } from 'shared/services/errorMessages'
import Confirm from 'shared/components/Confirm'
import { AppContext } from 'App'
import { IUserRegistration } from 'shared/models/user'
import VerifyUser from './VerifyUser'
import { fetchBbl } from 'shared/api/bbl'
import { IBbl } from 'shared/models/bbl'
import {
  createUser,
  createUserSubmitCode,
  createUserVerify,
} from 'shared/api/user'
import BateCreateUser from 'Modules/Bate/CreateUser'
import useBrandingTheme from 'shared/utils/useBrandingTheme'
import BateVerifyUser from 'Modules/Bate/VerifyUser'

interface IProps {}

const CreateUser: React.FC<IProps> = () => {
  const appContext = useContext(AppContext)
  const [bbl, setBbl] = useState<IBbl | null>(null)
  const [step, setStep] = useState(1)
  const [verificationCode, setVerificationCode] = useState<string | undefined>()
  const [user, setUser] = useState<IUserRegistration | undefined>()
  const [showDatepicker, setShowDatepicker] = useState(false)
  const [username, setUsername] = useState<string | undefined>()
  const [password, setPassword] = useState<string | undefined>()
  const [dateOfBirth, setDateOfBirth] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()
  const { brandingTheme } = useBrandingTheme()

  useEffect(() => {
    fetchBbl()
      .then((res: IBbl) => {
        setBbl(res)
      })
      .catch(() => {
        setBbl(null)
        setLoading(false)
      })
  }, [])

  const goBack = () => {
    if (loading) return
    history.goBack()
  }

  const onCancel = () => {
    setUser(undefined)
    setVerificationCode(undefined)
    setUsername(undefined)
    setPassword(undefined)
    setDateOfBirth(undefined)
    setStep(1)
  }

  /**
   *
   * API ACTIONS
   *
   */
  const submitCreate = (values: {
    username: string
    password: string
    birthDate: string | null
  }) => {
    setLoading(true)
    createUser(values)
      .then((res: AxiosResponse) => handleCreateSuccess(res, values))
      .catch((err) => handleCreateError(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const submitCode = (values: { username: string; code: string }) => {
    setLoading(true)
    createUserSubmitCode(values, password, dateOfBirth)
      .then((res: AxiosResponse) => handleCodeSuccess(res))
      .catch((err) => {
        setError(getRegistrationErrorMessage(err.response.data.code))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitVerify = () => {
    setLoading(true)
    createUserVerify(username, password, verificationCode, dateOfBirth)
      .then(() => handleVerifySuccess())
      .catch((err) => {
        setError(getRegistrationErrorMessage(err.response.data.code))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  /**
   *
   * END API ACTIONS
   *
   */

  /**
   *
   * HANDLERS
   *
   */
  const handleCreateSuccess = (res: AxiosResponse, values: any) => {
    if (res.data.code === RegistrationEnum.ConfirmationCodeSent) {
      setUsername(values.username)
      setPassword(values.password)
      setDateOfBirth(values.birthDate)
      setStep(2)
    }
  }

  const handleCodeSuccess = (res: AxiosResponse) => {
    setVerificationCode(res.data.verificationCode)
    setUser(res.data.user)
    setStep(3)
  }

  const handleVerifySuccess = () => {
    if (appContext.originReturnUrl) {
      window.location.href = appContext.originReturnUrl
    } else {
      history.push({
        pathname: '/login',
        search: `?bbl=${appContext.bbl}${
          appContext.returnUrl
            ? '&ReturnUrl=' + encodeURIComponent(appContext.returnUrl)
            : ''
        }`,
      })
    }
  }

  const handleCreateError = (err: any) => {
    if (
      err.response.data.code === RegistrationEnum.MoreThanOneResult &&
      showDatepicker
    ) {
      setError(getRegistrationErrorMessage(err.response.data.code))
    } else if (err.response.data.code === RegistrationEnum.MoreThanOneResult) {
      setShowDatepicker(true)
    } else {
      setError(getRegistrationErrorMessage(err.response.data.code))
    }
  }
  /**
   *
   * END HANDLERS
   *
   */

  if (brandingTheme === 'bate') {
    if (step === 3 && user) {
      return (
        <BateVerifyUser
          user={user}
          loading={loading}
          error={error}
          onDone={submitVerify}
          onCancel={onCancel}
          onRemoveError={() => setError(null)}
        />
      )
    }
    return (
      <BateCreateUser
        bbl={bbl}
        username={username}
        showDatepicker={showDatepicker}
        loading={loading}
        error={error}
        onRemoveError={() => setError(null)}
        onSubmit={submitCreate}
        onSubmitCode={submitCode}
        onGoBack={goBack}
      />
    )
  }

  const create = (
    <Create
      bbl={bbl}
      showDatepicker={showDatepicker}
      loading={loading}
      error={error}
      onRemoveError={() => setError(null)}
      onSubmit={submitCreate}
      onGoBack={goBack}
    />
  )

  const confirm = username ? (
    <Confirm
      username={username}
      loading={loading}
      error={error}
      onRemoveError={() => setError(null)}
      onSubmit={submitCode}
      onGoBack={goBack}
    />
  ) : (
    <Fragment>
      <Alert danger>En feil skjedde</Alert>
      <Link onClick={goBack}>Tilbake</Link>
    </Fragment>
  )

  const verify = user ? (
    <VerifyUser
      user={user}
      loading={loading}
      error={error}
      onDone={submitVerify}
      onCancel={onCancel}
      onRemoveError={() => setError(null)}
    ></VerifyUser>
  ) : (
    <Fragment>
      <Alert danger>En feil skjedde</Alert>
      <Link onClick={goBack}>Tilbake</Link>
    </Fragment>
  )

  return (
    <Layout>
      {step === 1 && create}
      {step === 2 && confirm}
      {step === 3 && verify}
    </Layout>
  )
}

export default CreateUser
