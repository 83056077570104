import { AnalyticsProvider } from '@bbld/bbl-analytics'
import { FC } from 'react'
import history from '../../browserHistory'
import { IBbl } from 'shared/models/bbl'

interface IProps {
  bbl: IBbl | null
  children?: React.ReactNode
}

const AnalyticsHandler: FC<IProps> = ({ bbl, children }: any) => {
  const analyticsSettings = {
    gtmCodes: [window.config.bblPivotalGtmCode],
    siteName: 'BBLiD',
    bblCode: window.config.bblCode,
  }
  if (bbl.settings.gtmId) {
    analyticsSettings.gtmCodes.push('GTM-' + bbl.settings.gtmId)
  }

  return (
    <AnalyticsProvider settings={analyticsSettings} history={history}>
      {children}
    </AnalyticsProvider>
  )
}

export default AnalyticsHandler
