export const ERROR_GENERAL_ERROR =
  'En feil skjedde. Prøv igjen. Om feilen vedvarer kontakt ditt boligbyggelag.'
export const ERROR_UNKNOW_ERROR = 'Ukjent feil'
export const ERROR_USER_EXISTS = `Det finnes allerede en bruker med denne e-postadressen/mobilnummeret. 
  Dersom du allerede har opprettet en bruker hos et boligbyggelag, kan du logge inn med ditt brukernavn og passord.`
export const ERROR_MULTIPLE_USERS =
  'Flere enn ett treff med denne informasjonen. Kontakt boligbyggelaget.'
export const ERROR_CONFIRMATION_ALREADY_SENT =
  'Bekreftelseskode er allerede sendt'
export const ERROR_CREATING_USER = 'En feil skjedde under oppretting av bruker'
export const ERROR_NO_USER_FOUND =
  'Vi kan ikke finne e-post eller mobilnummer registrert hos oss. Kontakt boligbyggelaget for bistand til oppretting ny bruker.'
export const ERROR_USER_DOES_NOT_EXIST =
  'Pålogging feilet, sjekk at e-post eller mobilnummer stemmer og at passordet er riktig.'
export const ERROR_INVALID_RETURN_URL = 'En feil skjedde under pålogging.'
export const ERROR_WRONG_USERNAME_OR_PASSWORD =
  'Pålogging feilet, sjekk at e-post eller mobilnummer stemmer og at passordet er riktig.'
export const ERROR_USER_GET_FAILED =
  'Feil under henting av bruker. Logg inn på nytt. Om problemet vedvarer kontakt ditt boligbyggelag.'
export const ERROR_USER_ACCESS_REQUEST_FAILED =
  'Feil under sending av forespørsel. Prøv igjen. Om feilen vedvarer kontakt ditt boligbyggelag.'
export const ERROR_INVALID_PASSWORD_FORMAT =
  'Passordet må minst være på 8 tegn, herav en stor bokstav, en liten bokstav og et tall.'
export const ERROR_WRONG_ONE_TIME_CODE = 'Feil engangskode angitt.'
export const ERROR_WRONG_CONFIRMATION_CODE = 'Feil engangskode.'
export const ERROR_EMAIL_INUSE = `Det finnes allerede en bruker med denne e-postadressen eller mobilnummeret. 
Dersom du allerede har opprettet en bruker hos et boligbyggelag, kan du logge inn med ditt brukernavn og passord.`
export const ERROR_PHONE_NUMBER_USED = `Det finnes allerede en bruker med dette mobilnummeret eller e-postadressen. 
Dersom du allerede har opprettet en bruker hos et boligbyggelag, kan du logge inn med ditt brukernavn og passord.`

export const ERROR_UNKNOWN =
  'Feil oppstod. Om problemet vedvarer kontakt ditt boligbyggelag.'

export const ERROR_FAILED_VERIFICATION = 'Feil verifikasjonskode'
export const ERROR_WRONG_PASSWORD = 'Feil passord'
export const ERROR_WRONG_EXSISTING_PASSWORD = 'Nåværende passord er feil.'
export const ERROR_EMAIL_INVALID = 'E-posten er ugyldig'
export const ERROR_PHONE_ALREADY_IN_USE = 'Telefonnummeret er allerede i bruk'
export const ERROR_EMAIL_ALREADY_IN_USE = 'E-post er allerede i bruk'

export const ERROR_DELETE_USER_WRONG_PASSWORD =
  'Det angitte passordet er ikke gyldig'
export const ERROR_DELETE_USER_FAILED = 'Feil oppstod ved sletting av bruker'

export const ERROR_CODE_TO_MANY_TRIES =
  'Du har tastet feil kode for mange ganger. Vennligst prøv på nytt.'

export const ERROR_MULTIPLE_PERSON_DATEOFBIRTH =
  'Det finnes flere med samme informasjon som er tastet inn. Ta kontakt for videre bistand.'

export const ERROR_RECAPTCHA =
  'Brukeren din kan ikke logges på med bruk av kode. \nVennligst logg på med brukernavn og passord.'

export const ERROR_VIPPS_WRONG_CODE_TEXT =
  'Tallet du klikket i vipps var feil. Prøv igjen eller kontakt Vipps om feilen vedvarer.'
export const ERROR_VIPPS_WRONG_CODE_HEADER = 'Feil tall'
