import React from 'react'
import {
  Button,
  Header,
  Link,
  Alert,
  Text,
  Datepicker,
} from '@bbl-digital/snorre'
import {
  ActionWrapper,
  Container,
  DatepickerWrapper,
  FormWrapper,
  KnutWrapper,
} from '../styles'
import { Formik, Form } from 'formik'

interface IProps {
  loading: boolean
  error: string | null
  onSubmit: (values: { dateOfBirth: string }) => void
  onBack: () => void
  onClearError: () => void
}

const DateOfBirthForm: React.FC<IProps> = ({
  loading,
  error,
  onSubmit,
  onBack,
  onClearError,
}) => {
  const submit = (values: { dateOfBirth: string }) => {
    onSubmit({
      dateOfBirth: values.dateOfBirth,
    })
  }

  return (
    <Container>
      <Formik
        initialValues={{
          dateOfBirth: '',
        }}
        onSubmit={(values) => {
          submit(values)
        }}
      >
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form>
            <FormWrapper>
              <Header level={1}>
                Verifisering
              </Header>
              <Text paragraph>
                For å identifisere deg trenger vi også din fødselsdato
              </Text>
              <KnutWrapper>
                <DatepickerWrapper>
                  <Datepicker
                    label="Fødselsdato"
                    value={
                      values.dateOfBirth
                        ? new Date(values.dateOfBirth)
                        : undefined
                    }
                    onChange={(e) => {
                      setFieldValue('dateOfBirth', e || '')
                      onClearError()
                    }}
                    onBlur={() =>
                      setTimeout(() => setFieldTouched('dateOfBirth'))
                    }
                  />
                </DatepickerWrapper>
              </KnutWrapper>
              {error && (
                <Alert danger onClose={onClearError}>
                  {error}
                </Alert>
              )}

              <ActionWrapper>
                <Button type="submit" loading={loading} highlight
				trackingName="Login"
				trackingEvent="Date of birth click: Bekreft">
                  Bekreft
                </Button>
                <Link onClick={onBack}>Tilbake</Link>
              </ActionWrapper>
            </FormWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default DateOfBirthForm
