import * as Yup from 'yup'
import regularExpressions from '@bbl-digital/snorre/lib/globals/validation/regularExpressions'

export const UserValidation = Yup.string()
  .max(50, 'Maks 50 tegn')
  .required('Påkrevd felt')

export const PasswordValidation = Yup.string()
  .max(50, 'Maks 50 tegn')
  .required('Påkrevd felt')

export const EmailValidation = Yup.string()
  .max(50, 'Maks 50 tegn')
  .email('Ugyldig epost')
  .required('Påkrevd felt')

export const PhoneValidation = Yup.string()
  .matches(regularExpressions.phonenumber, 'Ugyldig telefonnummer')
  .required('Påkrevd felt')

export const ConfirmationCode = Yup.string().required('Påkrevd felt')
