import styled from '@emotion/styled'

export const PasswordWrapper = styled.div`
  position: relative;

  input {
  }

  & > button {
    position: absolute;
    right: 1em;
    top: 2.4em;
  }
`
