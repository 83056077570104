import React from 'react'
import { Input, Button, Text, Alert } from '@bbl-digital/snorre'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ConfirmationCode, PasswordValidation } from 'shared/utils/validations'
import PasswordField from 'shared/components/PasswordField'

interface IProps {
  username: string
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onSubmit: (values: {
    username: string
    code: string
    password: string
  }) => void
}

const ConfirmUserSchema = Yup.object().shape({
  code: ConfirmationCode,
  password: PasswordValidation,
})

const ConfirmForm: React.FC<IProps> = ({
  username,
  loading,
  error,
  onSubmit,
  onRemoveError,
}) => {
  return (
    <Formik
      initialValues={{
        username: username,
        code: '',
        password: '',
      }}
      validationSchema={ConfirmUserSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Text paragraph>
            Vi har sendt deg en engangskode til {username}. Vennligst benytt
            denne engangskoden for å bekrefte din bruker.
          </Text>

          <Input
            type="text"
            label="Engangskode"
            focus
            validation={true}
            invalidMessage={errors.code && touched.code ? errors.code : null}
            onChange={(e: any) => {
              setFieldValue('code', e.target.value)
              onRemoveError()
              setTimeout(() => setFieldTouched('code'))
            }}
            autocomplete="off"
          />

          <PasswordField
            name="password"
            label="Bekreft endringen med ditt passord"
            validation={true}
            invalidMessage={
              errors.password && touched.password ? errors.password : null
            }
            onChange={(e: any) => {
              setFieldValue('password', e.target.value)
              onRemoveError()
              setTimeout(() => setFieldTouched('password'))
            }}
            autocomplete={false}
          />

          {error && (
            <Alert danger onClose={() => onRemoveError()}>
              {error}
            </Alert>
          )}

          <Button highlight type="submit" loading={loading}
		  trackingName="Profile"
		  trackingEvent="Edit email details click: Bekreft">
            Bekreft
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ConfirmForm
