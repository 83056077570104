import React, { useState, Fragment, useContext } from 'react'
import axios from 'axios'
import { Link, Alert } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { useHistory } from 'react-router-dom'
import RequestNewPassword from './RequestNewPassword'
import CreateNewPassword from './CreateNewPassword'
import NewPasswordResult from './NewPasswordResult'
import { AppContext } from 'App'
import {
  API_RECOVER_PASSORD,
  API_INITIATE_RECOVER_PASSWORD,
} from 'shared/constants/apiEndpoints'
import { getForgotPasswordErrorMessage } from 'shared/services/errorMessages'
import BateForgotPassword from 'Modules/Bate/ForgotPassword'
import useBrandingTheme from 'shared/utils/useBrandingTheme'

interface IProps {}

const ForgotPassword: React.FC<IProps> = () => {
  const appContext = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()
  const [user, setUser] = useState<string | undefined>()
  const [isSuccess, setSuccess] = useState(false)
  const { brandingTheme } = useBrandingTheme()

  const submit = (values: { username: string }) => {
    setLoading(true)
    axios
      .post(API_INITIATE_RECOVER_PASSWORD, {
        EmailOrPhone: values.username,
      })
      .then(() => {
        setUser(values.username)
      })
      .catch((err) => {
        setError(getForgotPasswordErrorMessage(err.response.data))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitConfirm = (values: {
    username: string
    code: string
    password: string
  }) => {
    setLoading(true)
    axios
      .post(API_RECOVER_PASSORD, {
        EmailOrPhone: values.username,
        Code: values.code,
        Password: values.password,
      })
      .then(() => {
        setSuccess(true)

        if (brandingTheme === 'bate') {
          history.push({
            pathname: '/login',
            search: `?bbl=${appContext.bbl}${
              appContext.returnUrl
                ? '&ReturnUrl=' + encodeURIComponent(appContext.returnUrl)
                : ''
            }`,
          })
        }
      })
      .catch((err) => {
        setError(getForgotPasswordErrorMessage(err.response.data))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const goToLogin = () => {
    history.push({
      pathname: '/login',
      search: `?bbl=${appContext.bbl}${
        appContext.returnUrl
          ? '&ReturnUrl=' + encodeURIComponent(appContext.returnUrl)
          : ''
      }`,
    })
  }

  const goBack = () => {
    history.goBack()
  }

  if (brandingTheme === 'bate')
    return (
      <BateForgotPassword
        user={user}
        loading={loading}
        error={error}
        onGoBack={goBack}
        onRemoveError={() => setError('')}
        onCodeSubmit={submitConfirm}
        onRequestSubmit={submit}
      />
    )

  const create = user ? (
    <CreateNewPassword
      username={user}
      loading={loading}
      error={error}
      onRemoveError={() => setError('')}
      onSubmit={submitConfirm}
      onGoBack={goBack}
    />
  ) : (
    <Fragment>
      <Alert danger>En feil skjedde</Alert>
      <Link onClick={goBack}>Tilbake</Link>
    </Fragment>
  )

  const request = (
    <RequestNewPassword
      error={error}
      loading={loading}
      onGoBack={goBack}
      onRemoveError={() => setError('')}
      onSubmit={submit}
    ></RequestNewPassword>
  )

  const result = (
    <NewPasswordResult
      buttonText="Logg inn"
      message="Passord ble endret. Trykk på knappen under for å logge inn."
      onButtonClick={goToLogin}
    ></NewPasswordResult>
  )

  return <Layout>{isSuccess ? result : user ? create : request}</Layout>
}

export default ForgotPassword
