import { Button } from '@bbl-digital/snorre'
import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;

  @media screen and (max-width: 400px) {
    margin-top: 50px;
  }

  & > form {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    width: 100%;
    max-width: 350px;

    & > h2 {
      margin-bottom: 0.5em;
    }
  }
`

export const CreateLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`

export const ProvidersWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const InputWrapper = styled.div``

export const VippsButton = styled(Button)`
  width: 100%;
  padding: 0;
  height: 46px;
  border-radius: 9999px;
  background: #ff5b24;
  border-color: #ff5b24;

  &:hover {
    background: #ff5b24;
    border-color: #ff5b24;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const VippsImage = styled.img`
  height: 40px;
`
