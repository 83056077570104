import React, { useEffect, Fragment } from 'react'
import { useAuthContext } from 'Auth/AuthContext'
import ProfileDetails from './Details'

interface IProps {}

const Profile: React.FC<IProps> = () => {
  const authContext = useAuthContext()

  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      authContext.createSigninRequest().then(() => {
        authContext.signinRedirect()
      })
    }
  }, [authContext])

  return (
    <Fragment>{authContext.isAuthenticated() && <ProfileDetails />}</Fragment>
  )
}

export default Profile
