import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import styled from '@emotion/styled'
import TelemetryProvider from 'shared/tracking/telemetryProvider'
import { getAppInsights } from 'shared/services/telemetryService'

import history from 'browserHistory'
import PageError from 'shared/components/PageError'

import Login from 'Modules/Login'
import LoginWithCode from 'Modules/LoginCode'
import CreateUser from 'Modules/CreateUser'
import ForgotPassword from 'Modules/ForgotPassword'
import Profile from 'Modules/Profile'
import Callback from 'Auth/Callback'
import Logout from 'Auth/Logout'
import NoAccess from 'Modules/NoAccess'
import PrivacyPage from 'Modules/PrivacyPage'
import VippsError from './VippsError'

const Routes: React.FC = () => {
  // eslint-disable-next-line
  let appInsights: any | null = null

  return (
    <Router history={history}>
      <TelemetryProvider
        instrumentationKey={window.config.instrumentationKey}
        after={() => {
          // eslint-disable-next-line
          appInsights = getAppInsights()
        }}
      >
        <Switch>
          {/* Auth routes */}
          <Route path="/signin-oidc" component={Callback} />
          <Route path="/logout" component={Logout} />

          <Route path="/privacy" exact component={PrivacyPage} />

          <Route path="/vippsError" exact component={VippsError} />

          <Route path="/login" exact component={Login} />
          <Route path="/loginWithCode" exact component={LoginWithCode} />
          <Route path="/opprett" exact component={CreateUser} />
          <Route path="/glemt-passord" exact component={ForgotPassword} />
          <Route path="/profil" exact component={Profile} />

          <Route path="/ikketilgang" exact component={NoAccess} />

          {/* Error routes */}
          <Route component={PageError} />
        </Switch>
      </TelemetryProvider>
    </Router>
  )
}

export default Routes
