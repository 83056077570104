import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LOCAL_STORAGE_VIPPS_SAVED_URL } from 'shared/constants/localStorage'
import { getParamBothLowerUppercase } from 'shared/utils/params'

const VippsError = () => {
  const history = useHistory()
  const error = getParamBothLowerUppercase('error')

  useEffect(() => {
    const redirect_url = localStorage.getItem(LOCAL_STORAGE_VIPPS_SAVED_URL)

    localStorage.removeItem(LOCAL_STORAGE_VIPPS_SAVED_URL)

    console.log('error', error)

    go(`${redirect_url}&vippsError=${error}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const go = (url: string) => {
    history.replace(url)
  }

  return <div></div>
}

export default VippsError
