export const getModalHeader = (type: string | undefined) => {
  switch (type) {
    case 'email':
      return 'Endre e-postadresse'
    case 'mobile':
      return 'Endre mobilnummer'
    case 'password':
      return 'Endre passord'
    case 'delete':
      return 'Slett din bruker'
    case 'confirm-mobile':
      return 'Bekreft mobil'
    case 'confirm-email':
      return 'Bekreft e-postadresse'
    default:
      return ''
  }
}
