import React, { Fragment, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { Button, Text, Alert, List, ListItem, Link } from '@bbl-digital/snorre'
import { EditWrapper } from '../styles'
import { useAuthContext } from 'Auth/AuthContext'
import { API_DELETE_USER } from 'shared/constants/apiEndpoints'
import { IBbl } from 'shared/models/bbl'
import { getDeleteProfileErrorMessage } from 'shared/services/errorMessages'
import ConfirmForm from './ConfirmForm'
import { IPerson } from 'shared/models/person'
import { ActionWrappers } from './styles'
import { RegistrationEnum } from 'shared/models/enums'

interface IProps {
  onDone: () => void
  onCancel: () => void
  bbls: IBbl[]
  person: IPerson
}

enum Steps {
  Request,
  Code,
}

const DeleteUser: React.FC<IProps> = ({ onDone, onCancel, bbls, person }) => {
  const authContext = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState<string | undefined>()
  const [error, setError] = useState<string | null>(null)
  const [step, setStep] = useState(Steps.Request)

  const listBbls = () => {
    const bbllist = []
    for (var x = 0; x < bbls.length; x++) {
      bbllist.push(<ListItem key={x}>{bbls[x].name}</ListItem>)
    }
    return bbllist
  }

  const requestCode = (user: string) => {
    setLoading(true)
    axios
      .delete(API_DELETE_USER, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
        data: { EmailOrPhone: user, Code: null },
      })
      .then((res: AxiosResponse) => {
        setUsername(user)
        setStep(Steps.Code)
      })
      .catch((err) => {
        if (err.response.data === RegistrationEnum.ConfirmationCodeSent) {
          setUsername(user)
          setStep(Steps.Code)
        } else {
          setError(getDeleteProfileErrorMessage(err.response.data))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitCode = (code: string) => {
    setLoading(true)
    axios
      .delete(API_DELETE_USER, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
        data: { EmailOrPhone: username, Code: code },
      })
      .then((res: AxiosResponse) => {
        authContext.logoutGateway()
      })
      .catch((err) => {
        setError(getDeleteProfileErrorMessage(err.response.data))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <EditWrapper>
      {step === Steps.Request && (
        <Fragment>
          <Text>
            Ved å slette din bruker, vil du ikke lenger kunne logge på flere av
            tjenestene for følgende boligbyggelag:
          </Text>
          <List points>{listBbls()}</List>

          {error && <Alert danger>{error}</Alert>}

          <ActionWrappers>
            {!person.phoneNumberConfirmed && person.emailConfirmed && (
              <Button
                danger
                type="submit"
                onClick={() => requestCode(person.email)}
                loading={loading}
				trackingName="Profile"
				trackingEvent="Delete user details click: Slett bruker"
              >
                Slett bruker
              </Button>
            )}
            {person.phoneNumberConfirmed && (
              <Button
                danger
                type="submit"
                onClick={() => requestCode(person.phoneNumber)}
                loading={loading}
				trackingName="Profile"
				trackingEvent="Delete user details click: Slett bruker"
              >
                Slett bruker
              </Button>
            )}
            <Link onClick={onCancel}>Avbryt</Link>
          </ActionWrappers>
          <Text subtle>
            Du kan opprette ny bruker etter å ha slettet nåværende bruker. Ta
            gjerne kontakt med oss dersom du har spørsmål til noen av våre
            tjenester.
          </Text>
        </Fragment>
      )}
      {step === Steps.Code && (
        <ConfirmForm
          username={username}
          error={error}
          loading={loading}
          onRemoveError={() => setError(null)}
          onSubmit={(values) => submitCode(values.code)}
        />
      )}
    </EditWrapper>
  )
}

export default DeleteUser
