import React, { useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import ConfirmForm from './ConfirmForm'
import { EditWrapper } from '../styles'
import { getUpdateProfileErrorMessage } from 'shared/services/errorMessages'
import { useAuthContext } from 'Auth/AuthContext'
import PhoneForm from './PhoneForm'
import {
  API_UPDATE_PROFILE,
  API_UPDATE_PROFILE_WITH_CODE,
} from 'shared/constants/apiEndpoints'
import {
  ERROR_FAILED_VERIFICATION,
  ERROR_WRONG_PASSWORD,
} from 'shared/constants/errorMessages'
import { removeSpaces } from 'shared/utils/string'

interface IProps {
  phone: string
  onDone: () => void
  onCancel: () => void
}

const EditMobile: React.FC<IProps> = ({ phone, onDone, onCancel }) => {
  const authContext = useAuthContext()
  const [newPhone, setNewPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [confirm, setConfirm] = useState(false)

  const submitForm = (values: { phone: string }) => {
    setLoading(true)
    axios
      .put(
        API_UPDATE_PROFILE,
        {
          Phone: removeSpaces(values.phone),
        },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )
      .then((res: AxiosResponse) => {
        setNewPhone(values.phone)
        setConfirm(true)
      })
      .catch((err) => {
        if (err.data.wrongPassword) {
          setError(ERROR_WRONG_PASSWORD)
        } else {
          setError(getUpdateProfileErrorMessage(err.response.data.errorCode))
        }
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitConfirm = (values: {
    username: string
    code: string
    password: string
  }) => {
    setLoading(true)
    axios
      .put(API_UPDATE_PROFILE_WITH_CODE + values.code, {
        Phone: values.username,
        Password: values.password,
      },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        })
      .then((res: AxiosResponse) => {
        if (res.data.updatedValue) {
          onDone()
        } else if (res.data.verificationCodeError) {
          setError(ERROR_FAILED_VERIFICATION)
        } else if (res.data.wrongPassword) {
          setError(ERROR_WRONG_PASSWORD)
        }
      })
      .catch((err) => {
        setError(getUpdateProfileErrorMessage(err.response.data.code))
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const phoneform = (
    <PhoneForm
      phone={phone}
      loading={loading}
      error={error}
      onSubmit={submitForm}
      onCancel={onCancel}
      onRemoveError={() => setError(null)}
    ></PhoneForm>
  )

  const confirmform = (
    <ConfirmForm
      username={newPhone}
      loading={loading}
      error={error}
      onRemoveError={() => setError(null)}
      onSubmit={submitConfirm}
    ></ConfirmForm>
  )

  return <EditWrapper>{confirm ? confirmform : phoneform}</EditWrapper>
}

export default EditMobile
