import { VIPPS_GATEWAY_PATH } from 'shared/constants/urls'

export const getUrl = (url?: string) => {
  if (!url) {
    return ''
  }
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return 'http://' + url
  }
  return url
}

export const getVippsUrl = (returnUrl: string | null) =>
  window.config.bblidGatewayUrl + VIPPS_GATEWAY_PATH + returnUrl
