import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2.5em;
  max-width: 550px;
  margin: 0 auto;
  margin-top: 32px;

  & > span {
    margin-top: -0.5em;
    line-height: 1.7em;
  }
`

export const CardContent = styled.div`
  display: grid;
  justify-items: center;
  padding: 20px 20px;
  grid-row-gap: 1.5em;
  text-align: center;

  & > span {
    text-align: center;
  }
`

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: 120px auto 80px;
  grid-row-gap: 1.5em;
  grid-column-gap: 1em;
  align-items: center;
  margin-top: 1em;

  & > span:nth-of-type(1),
  & > span:nth-of-type(3),
  & > span:nth-of-type(5) {
    text-align: right;
  }

  & > span:nth-of-type(2),
  & > span:nth-of-type(4),
  & > span:nth-of-type(6) {
    text-align: left;
  }

  button {
    text-align: left;
  }

  @media screen and (max-width: 770px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 0.5em;
    flex-flow: row;
    width: 100%;

    & > span:nth-of-type(1),
    & > span:nth-of-type(3),
    & > span:nth-of-type(5) {
      text-align: left;
    }

    button:not(.confirm-contact) {
      margin-left: 80%;
      margin-top: -90px;
    }
  }
`

export const HeaderWrapper = styled.div``

export const EditWrapper = styled.div`
  padding-bottom: 1em;
  & > form {
    display: grid;
    grid-row-gap: 1em;
    padding-top: 0em;
    margin-top: 1em;

    & > button:nth-of-type(1) {
      margin-top: 1em;
    }
  }
`

export const AlertWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1em;
  margin-top: 3em;

  & > button {
    width: 120px;
  }
`

export const ConfirmWrapper = styled.div`
  display: grid;
  grid-column: span 3;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.02);
  padding: 1em;
  margin: auto;
  justify-items: center;
  border-radius: 5px;

  & > Button {
    margin-top: 1em;
  }

  @media screen and (max-width: 770px) {
    grid-column: 1;
    margin: unset;
  }
`
