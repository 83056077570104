import React, { Fragment } from 'react'
import {
  Input,
  Button,
  Text,
  Link,
  Alert,
  Card,
  Header,
} from '@bbl-digital/snorre'
import Datepicker from '@bbl-digital/snorre/lib/core/Datepicker'
import { CardContent } from 'shared/components/CardContent'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { PasswordValidation, UserValidation } from 'shared/utils/validations'
import PasswordField from 'shared/components/PasswordField'
import { DatepickerWrapper } from './styles'
import { getParsedDate } from 'shared/utils/dates'

interface IProps {
  showDatepicker: boolean
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onGoBack: () => void
  onSubmit: (values: {
    username: string
    password: string
    birthDate: string | null
  }) => void
}

export const CreateUserSchema = Yup.object().shape({
  username: UserValidation,
  password: PasswordValidation,
})

const CreateForm: React.FC<IProps> = ({
  showDatepicker,
  loading,
  error,
  onSubmit,
  onRemoveError,
  onGoBack,
}) => {
  return (
    <Card shadow>
      <CardContent>
        <Header level={2}>Opprett ny bruker</Header>

        <Formik
          initialValues={{
            username: '',
            password: '',
            birthDate: null,
          }}
          validationSchema={CreateUserSchema}
          onSubmit={(values) => {
            onSubmit(values)
          }}
        >
          {({ errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              {!showDatepicker && (
                <Fragment>
                  <Input
                    name="username"
                    type="text"
                    label="E-post eller mobilnummer"
                    focus
                    validation={true}
                    invalidMessage={
                      errors.username && touched.username
                        ? errors.username
                        : null
                    }
                    onChange={(e: any) => {
                      setFieldValue('username', e.target.value)
                      onRemoveError()
                      setTimeout(() => setFieldTouched('username'))
                    }}
                  />

                  <PasswordField
                    name="password"
                    label="Ønsket passord"
                    validation={true}
                    invalidMessage={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                    onChange={(e: any) => {
                      setFieldValue('password', e.target.value)
                      onRemoveError()
                      setTimeout(() => setFieldTouched('password'))
                    }}
                  />

                  <Text subtle>
                    Passordet må ha minst 8 tegn. Må inkludere store og små
                    bokstaver, samt tall.
                  </Text>
                </Fragment>
              )}

              {showDatepicker && (
                <DatepickerWrapper>
                  <Text>
                    For å identifisere deg trenger vi også din fødselsdato
                  </Text>
                  <Datepicker
                    label="Fødselsdato"
                    onChange={(date: any) => {
                      setFieldValue('birthDate', getParsedDate(date))
                      onRemoveError()
                      setTimeout(() => setFieldTouched('birthDate'))
                    }}
                  ></Datepicker>
                </DatepickerWrapper>
              )}

              {error && (
                <Alert danger onClose={() => onRemoveError()}>
                  {error}
                </Alert>
              )}

              <Button highlight type="submit" loading={loading}
			  trackingName="Create user"
			  trackingEvent="Create form click: Bekreft">
                Bekreft
              </Button>
              <Link onClick={onGoBack}>Avbryt</Link>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default CreateForm
