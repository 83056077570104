const search = window.location.search
const params = new URLSearchParams(search)
const bblCode = params.get('bbl')
const bblidUrl = window.config?.bblidBaseUrl
const appUrl = window.config?.baseUrl

const clientId = window.config?.clientId

export const IDENTITY_CONFIG = {
  authority: bblidUrl,
  client_id: clientId,
  redirect_uri: appUrl + 'signin-oidc?bbl=' + bblCode,
  silent_redirect_uri: appUrl + '/silentredirect',
  post_logout_redirect_uri: appUrl + '/logout',
  // audience: process.env.REACT_APP_AUDIENCE,
  response_type: 'id_token token',
  automaticSilentRenew: false,
  loadUserInfo: false,
  scope: 'openid api',
}
