import React, { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { AppShell, AppLoader } from '@bbl-digital/snorre'
import { initAxios } from 'axios-config'
import { IBbl } from 'shared/models/bbl'
import { AppLoaderWrapper } from './styles'
import { getTheme, setFavicon } from 'shared/utils/theme'
import Routes from './Routes'
import ErrorBoundary from 'ErrorBoundary'
import { AuthProvider } from 'Auth/AuthContext'
import { API_GET_BBL } from 'shared/constants/apiEndpoints'
import { getParamBothLowerUppercase } from 'shared/utils/params'
import AnalyticsHandler from './AnalyticsHandler'
import useBrandingTheme from 'shared/utils/useBrandingTheme'

const DefaultTheme = React.lazy(() => import('./Themes/Default/DefaultTheme'))
const BateTheme = React.lazy(() => import('./Themes/Bate'))

export interface IAppContext {
  bbl: string | null
  loginWithCode: boolean
  enableVipps: boolean
  enableMFA: boolean
  returnUrl: string | null
  originReturnUrl: string | null
}

export const AppContext = React.createContext<IAppContext>({
  bbl: 'bbld',
  loginWithCode: false,
  enableVipps: false,
  enableMFA: false,
  returnUrl: null,
  originReturnUrl: null,
})

const App: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [bbl, setBbl] = useState<IBbl | null>(null)
  const [theme, setTheme] = useState<any>()
  const [codeEnabled, setCodeEnabled] = useState(false)
  const bblCode = getParamBothLowerUppercase('bbl')
  const returnUrl = getParamBothLowerUppercase('ReturnUrl')
  const originReturnUrl = getParamBothLowerUppercase('OriginReturnUrl')
  const { brandingTheme } = useBrandingTheme()

  useEffect(() => {
    if (!bblCode) throw new Error('BBL ikke spesifisert')

    window.config.bblCode = bblCode

    initAxios(bblCode)
    axios
      .get(API_GET_BBL)
      .then((res: AxiosResponse<IBbl>) => {
        setBbl(res.data)
        document.title = res.data.shortName || 'Pålogging'
        setCodeEnabled(res.data.settings.enableMFA)

        if (res.data.brandingCode) {
          getTheme(res.data.brandingCode)
            .then((themeRes) => {
              setTheme(themeRes.data)
            })
            .catch((err) => {
              setLoading(false)
            })
            .finally(() => {
              setLoading(false)
            })
          setFavicon(res.data.brandingCode)
        }
      })
      .catch((err) => {
        setBbl(null)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bblCode, setBbl, setLoading, getTheme])

  const App = (
    <ErrorBoundary>
      <AppContext.Provider
        value={{
          bbl: bblCode,
          loginWithCode: codeEnabled,
          enableVipps: bbl?.settings.enableVipps ? true : false,
          enableMFA: bbl?.settings.enableMFA ? true : false,
          returnUrl: returnUrl,
          originReturnUrl: originReturnUrl,
        }}
      >
        <AuthProvider>
          <AppShell theme={theme}>
            <AnalyticsHandler bbl={bbl}>
              {brandingTheme !== 'bate' && (
                <DefaultTheme bbl={bbl}>
                  <Routes />
                </DefaultTheme>
              )}

              {brandingTheme === 'bate' && (
                <BateTheme>
                  <Routes />
                </BateTheme>
              )}
            </AnalyticsHandler>
          </AppShell>
        </AuthProvider>
      </AppContext.Provider>
    </ErrorBoundary>
  )

  const AppLoading = (
    <AppLoaderWrapper>
      <AppLoader />
    </AppLoaderWrapper>
  )

  return loading ? AppLoading : App
}

export default App
