import React, { useEffect } from 'react'
import { Card, Header, Text, Button, Layout } from '@bbl-digital/snorre'
import { useHistory } from 'react-router-dom'
import { Wrapper, Section, Break } from './styles'

const PrivacyPage: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const goBack = () => {
    history.goBack()
  }

  return (
    <Layout>
      <Wrapper>
        <Button back onClick={goBack}
		trackingName="Privacy page"
		trackingEvent="Privacy page click: Gå tilbake">
          Gå tilbake
        </Button>
        <Card largePadding shadow>
          <Section>
            <Header level={3}>Personvernerklæring</Header>
            <Text paragraph>
              Personvernerklæringen handler om hvordan ditt boligbyggelag
              forvalter informasjon du som besøkende avgir på boligbyggelagets
              nettsteder. Erklæringen inneholder informasjon du har krav på når
              det samles inn opplysninger fra nettstedet vårt, og generell
              informasjon om hvordan vi behandler personopplysningene i henhold
              til personopplysningsloven § 19 og 18, 1.ledd.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Behandlingsansvarlige</Header>
            <Text paragraph>
              Direktøren i boligbyggelaget er behandlingsansvarlig for
              personopplysninger som behandles. Til daglig er det
              medlemsavdelingen til boligbyggelaget som har forvaltningsansvaret
              for medlemsregisteret. Selskaper som eies fullt ut av flere
              boligbyggelag kan være databehandlere etter inngått
              databehandleravtale. Enkelte tjenester eies av andre enn
              boligbyggelaget. Eierne av disse tjenestene er
              databehandleransvarlige for sine tjenester. De kan ha andre
              analyseverktøy enn boligbyggelaget, og lagre andre
              personopplysninger enn de boligbyggelaget lagrer.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Formålet</Header>
            <Text paragraph>
              Formålet med å behandle personopplysningene er å oppfylle inngått
              avtale mellom deg som kunde og boligbyggelaget, slik at dine
              rettigheter ivaretas. I tillegg skal de trygge en god
              medlemsservice. Våre rutiner skal sikre at opplysningene ikke
              kommer på avveie, eller misbrukes ut over ditt samtykke.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Personopplysninger som behandles</Header>
            <Text paragraph>
              Ved innmelding som medlem av boligbyggelaget, registreres dato når
              innbetalingen finner sted samt navn, fødselsnummer, adresse,
              telefonnummer, medlemskontingent og andelsinnskudd. For å sikre
              nødvendig oppdatert informasjon kan det hende boligbyggelaget må
              innhente informasjon fra andre kilder, eksempelvis offentlig
              registre, eller andre lovlige registre. Under Min side kan
              brukeren selv legge til frivillig informasjon.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Utlevering av personopplysninger</Header>
            <Text paragraph>
              Med unntak av offentlige registre som stiller krav til utlevering
              vil ikke boligbyggelaget gi fra seg personopplysninger til andre
              uten samtykke.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Endre samtykket</Header>
            <Text paragraph>
              Ved å henvende deg til boligbyggelagets medlemsavdeling vil du
              alltid kunne endre, få innsyn, eller slette samtykket. Ved
              tilbakekallelse av samtykke mister du tilgangen til fordelene du
              har tilgjengelig som medlem i boligbyggelaget.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Sletting av opplysninger</Header>
            <Text paragraph>
              Ved utmelding av medlemskap slettes alle personopplysninger med
              unntak av lovpålagt opplysningsplikt.
            </Text>
          </Section>
          <Section>
            <Header level={4}>Sikkerhet</Header>
            <Text paragraph>
              Mange av de tjenestene som tilbys bruker felles pålogging for
              innlogging. Enkelte tjenester har krav til BankID-pålogging. Ved
              side – og tjenestefunksjonalitet som krever innlogging, kan det
              bli brukt informasjonskapsler som sikrer at tjenesten presenterer
              data til rett mottaker. Som en viktig del av arbeidet med å lage
              et brukervennlig nettsted, ser vi på brukermønsteret til de som
              besøker nettstedet vårt.
            </Text>
            <Break />
            <Text paragraph>
              Til å samle og analysere informasjonen bruker vi forskjellige
              analyseverktøy, blant annet Google Analytics. Verktøyene bruker
              informasjonskapsler/cookies (små tekstfiler som nettstedet lagrer
              på brukerens datamaskin) til å registrere brukernes maskin, og gir
              da informasjon om den enkelte «brukers» maskin sin bevegelser på
              nett. Eksempler på hva statistikken kan gi oss svar på er; hvor
              mange som besøker ulike sider, hvor lenge besøket varer, hvilke
              nettsteder «brukerne» kommer fra og hvilke nettlesere som
              benyttes. Ingen informasjon i informasjonskapslene gjør at vi kan
              knytte informasjonen til deg som enkeltperson. Vi bruker Google
              Analytics sin sporingskode som anonymiserer maskinadressen før
              informasjonen lagres og bearbeides. Dermed kan ikke maskinadressen
              brukes til å identifisere den enkelte maskin eller brukeren av
              denne. Formålet med lagringen er å gjøre informasjonstilbudet vårt
              bedre til dere som sluttbrukere.
            </Text>
          </Section>
        </Card>
      </Wrapper>
    </Layout>
  )
}

export default PrivacyPage
