import React, { Fragment, useState } from 'react'
import { Button, Header, Alert, Input, Text } from '@bbl-digital/snorre'
import {
  ActionWrapper,
  Container,
  FormWrapper,
  KnutWrapper,
  PageLoaderWrapper,
  VippsImage,
} from '../styles'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
  EmailValidation,
  PhoneValidation,
  UserValidation,
} from '../../../shared/utils/validations'
import { getVippsUrl } from 'shared/utils/url'
import { LOCAL_STORAGE_VIPPS_SAVED_URL } from 'shared/constants/localStorage'
import PageLoader from 'shared/components/PageLoader'

const SigninSchema = Yup.object().shape({
  username: UserValidation,
})

interface IProps {
  loading: boolean
  error: string | null
  vipps: boolean
  vippsReturnUrl: string | null
  onSubmit: (values: { username: string }) => void
  onBack: () => void
  onUsePasswordLogin: () => void
  onClearError: () => void
}

const UsernameForm: React.FC<IProps> = ({
  loading,
  error,
  vipps,
  vippsReturnUrl,
  onSubmit,
  onBack,
  onUsePasswordLogin,
  onClearError,
}) => {
  const vippsUrl = getVippsUrl(vippsReturnUrl)
  const [pageLoading, setPageLoading] = useState(false)
  const [validationError, setValidationError] = useState<string | undefined>()
  const submit = (values: { username: string }) => {
    if (values.username.indexOf('@') === -1) {
      PhoneValidation.validate(values.username.replace(/\s+/g, ''))
        .then(() => onSubmit(values))
        .catch((err) => setValidationError(err.message))
    } else {
      EmailValidation.validate(values.username)
        .then(() => onSubmit(values))
        .catch((err) => setValidationError(err.message))
    }
  }

  const goToVipps = () => {
    setPageLoading(true)

    localStorage.setItem(
      LOCAL_STORAGE_VIPPS_SAVED_URL,
      window.location.pathname + window.location.search
    )

    window.location.href = vippsUrl
  }

  return (
    <Container>
      <Formik
        initialValues={{
          username: '',
        }}
        validationSchema={SigninSchema}
        onSubmit={(values) => {
          if (loading) return
          submit(values)
        }}
      >
        {({ errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <FormWrapper>
              <Header level={2}>Logg inn / Registrer deg</Header>
              <Text paragraph>
                Skriv inn ditt telefonnummer eller e-post for å logge inn
                <br /> eller registrere ny bruker.
              </Text>
              <KnutWrapper>
                <Input
                  name="username"
                  type="text"
                  value=""
                  label=""
                  focus
                  validation={true}
                  invalidMessage={
                    (errors.username && touched.username
                      ? errors.username
                      : null) ||
                    (validationError && validationError)
                  }
                  onChange={(e: any) => {
                    setFieldValue('username', e.target.value)
                    setValidationError(undefined)
                    onClearError()
                  }}
                />
              </KnutWrapper>
              {error && (
                <Alert danger onClose={onClearError}>
                  {error}
                </Alert>
              )}

              <ActionWrapper>
                <Button type="submit" loading={loading} highlight
				trackingName="Login Code"
				trackingEvent="User name form click: Send meg en kode">
                  Send meg en kode
                </Button>
                <Text>Benytt annen innloggingsmetode</Text>
                {vipps && (
                  <Fragment>
                    <Button className="vipps" onClick={goToVipps}
					trackingName="Login Code"
					trackingEvent="User name form click: Gå til vipps">
                      <VippsImage
                        src={
                          window.config.themeUrl +
                          'shared/images/Vipps-Login-Btn.svg'
                        }
                      />
                    </Button>
                  </Fragment>
                )}
                <Button className="userpw" outline onClick={onUsePasswordLogin}
				trackingName="Login Code"
				trackingEvent="User name form click: Logg inn med brukernavn og passord">
                  Logg inn med brukernavn og passord
                </Button>
              </ActionWrapper>
            </FormWrapper>
          </Form>
        )}
      </Formik>
      {pageLoading && (
        <PageLoaderWrapper>
          <PageLoader />
        </PageLoaderWrapper>
      )}
    </Container>
  )
}

export default UsernameForm
