import styled from '@emotion/styled'
import bp from '@bbl-digital/snorre/lib/globals/styles/breakpoints'

export const CardContent = styled.div`
  display: grid;
  padding: 20px 40px;
  grid-row-gap: 1em;

  @media screen and (max-width: ${bp.small}) {
    padding: 1em 0;
  }

  & > h2 {
    line-height: 48px;
    margin-bottom: 12px;
  }

  & > span {
    margin-bottom: 1em;
  }

  & button + span {
    text-align: center;
  }

  & > p {
    line-height: 24px;
  }

  & form {
    display: grid;
    grid-row-gap: 1em;

    button.link {
      justify-self: end;
      padding: 0;
    }
  }
`
