import React, { useState } from 'react'
import { PasswordWrapper } from './styles'
import { Input, Button, IconEyeDeny, IconEye } from '@bbl-digital/snorre'

interface IProps {
  name: string
  label: string
  invalidMessage: string | null
  validation: boolean
  autocomplete?: boolean
  onChange: (e: any) => void
  onBlur?: () => void
}

const PasswordField: React.FC<IProps> = ({
  name,
  label,
  validation,
  invalidMessage,
  autocomplete = true,
  onChange,
  onBlur,
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  return (
    <PasswordWrapper>
      <Input
        name={name}
        type={isPasswordVisible ? 'text' : 'password'}
        label={label}
        maxlength="50"
        validation={validation}
        autocomplete={autocomplete ? 'on' : 'off'}
        invalidMessage={invalidMessage}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Button
        nostyle
        onClick={() => setPasswordVisible(!isPasswordVisible)}
      >
        {isPasswordVisible && <IconEyeDeny />}
        {!isPasswordVisible && <IconEye />}
      </Button>
    </PasswordWrapper>
  )
}

export default PasswordField
