import { useAuthContext } from 'Auth/AuthContext'
import axios from 'axios'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import BateModal from 'shared/components/BateModal'
import { API_UPDATE_PASSWORD } from 'shared/constants/apiEndpoints'
import { UpdateProfileEnum } from 'shared/models/enums'
import { getUpdateProfileErrorMessage } from 'shared/services/errorMessages'
import { ConfirmationCode, PasswordValidation } from 'shared/utils/validations'
import {
  ERROR_INVALID_PASSWORD_FORMAT,
  ERROR_WRONG_EXSISTING_PASSWORD,
  ERROR_WRONG_ONE_TIME_CODE,
  ERROR_GENERAL_ERROR,
} from 'shared/constants/errorMessages'

const ConfirmUserSchema = Yup.object().shape({
  code: ConfirmationCode,
})

export const PasswordSchema = Yup.object().shape({
  password: PasswordValidation,
})

interface IProps {
  username: string
  onDone: () => void
}

const ChangePassword = ({ username, onDone }: IProps) => {
  const authContext = useAuthContext()
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [submitError, setSubmitError] = useState<string | undefined>()
  const close = () => setPassword(undefined)

  const submitForm = async (values: { password: string }) => {
    setPassword(values.password)

    try {
      const res = await axios.put(
        API_UPDATE_PASSWORD,
        {
          Code: undefined,
          NewPassword: values.password,
          EmailOrPhone: username,
        },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )

      if (res.data.errorCode === UpdateProfileEnum.FailedValidation) {
        setSubmitError(ERROR_INVALID_PASSWORD_FORMAT)
      } else if (res.data.wrongPassword) {
        setSubmitError(ERROR_WRONG_EXSISTING_PASSWORD)
      } else {
        setPassword(values.password)
      }
    } catch (err: any) {
      setSubmitError(getUpdateProfileErrorMessage(err.response.data.code))
    }
  }

  const submitCode = async ({ code }: { code: string }) => {
    try {
      const res = await axios.put(
        API_UPDATE_PASSWORD,
        {
          Code: code,
          NewPassword: password,
          EmailOrPhone: username,
        },
        {
          headers: { Authorization: `Bearer ${authContext.getToken()}` },
        }
      )

      if (res && res.data) {
        const {
          errorCode,
          wrongPassword,
          verificationCodeError,
          updatedValue,
        } = res.data

        if (errorCode === UpdateProfileEnum.FailedValidation) {
          setError(ERROR_INVALID_PASSWORD_FORMAT)
        } else if (wrongPassword) {
          setError(ERROR_WRONG_EXSISTING_PASSWORD)
        } else if (verificationCodeError) {
          setError(ERROR_WRONG_ONE_TIME_CODE)
        } else if (updatedValue) {
          onDone()
        } else {
          setError(ERROR_GENERAL_ERROR)
        }
      } else {
        setError(ERROR_GENERAL_ERROR)
      }
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.code) {
        setError(getUpdateProfileErrorMessage(err.response.data.code))
      } else {
        setError(ERROR_GENERAL_ERROR)
      }
    } finally {
      close()
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={PasswordSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}
      >
        {({ errors, setFieldValue, setFieldTouched }) => (
          <Form>
            <label className="text-red font-clanot-black">Passord</label>
            <div className="edit-input">
              <div className="password flex-1">
                <input
                  name="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  className="input-text"
                  placeholder="Skriv inn her"
                  onChange={(e: any) => {
                    setFieldValue('password', e.target.value)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setFieldTouched('password')
                    }, 300)
                  }}
                />
                <button
                  type="button"
                  className="eye"
                  onClick={() => setPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? (
                    <svg
                      viewBox="0 0 24 24"
                      width="24px"
                      height="24px"
                      role="img"
                      fill="#dc151d"
                    >
                      <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z"></path>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 24 24"
                      width="24px"
                      height="24px"
                      role="img"
                      fill="#dc151d"
                    >
                      <path d="M12,7 C14.76,7 17,9.24 17,12 C17,12.65 16.87,13.26 16.64,13.83 L19.56,16.75 C21.07,15.49 22.26,13.86 22.99,12 C21.26,7.61 16.99,4.5 11.99,4.5 C10.59,4.5 9.25,4.75 8.01,5.2 L10.17,7.36 C10.74,7.13 11.35,7 12,7 Z M2,4.27 L4.28,6.55 L4.74,7.01 C3.08,8.3 1.78,10.02 1,12 C2.73,16.39 7,19.5 12,19.5 C13.55,19.5 15.03,19.2 16.38,18.66 L16.8,19.08 L19.73,22 L21,20.73 L3.27,3 L2,4.27 Z M7.53,9.8 L9.08,11.35 C9.03,11.56 9,11.78 9,12 C9,13.66 10.34,15 12,15 C12.22,15 12.44,14.97 12.65,14.92 L14.2,16.47 C13.53,16.8 12.79,17 12,17 C9.24,17 7,14.76 7,12 C7,11.21 7.2,10.47 7.53,9.8 L7.53,9.8 Z M11.84,9.02 L14.99,12.17 L15.01,12.01 C15.01,10.35 13.67,9.01 12.01,9.01 L11.84,9.02 Z"></path>{' '}
                    </svg>
                  )}
                </button>
              </div>
              <button
                type="submit"
                className="btn-link edit-input__button"
                onClick={() => console.log(errors)}
              >
                Lagre
              </button>
            </div>
            {submitError && (
              <p className="login-error text-red mb-1 mt-1">{submitError}</p>
            )}
          </Form>
        )}
      </Formik>
      {password ? (
        <BateModal title="Endre passord" onClose={close}>
          <Formik
            initialValues={{
              code: '',
            }}
            validationSchema={ConfirmUserSchema}
            onSubmit={(values) => {
              submitCode(values)
            }}
          >
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <p style={{ color: '#666' }}>
                  Vi har sendt deg en engangskode til {username}. Vennligst
                  benytt denne engangskoden for å bekrefte din bruker.
                </p>

                <div className="form">
                  <label className="text-red font-clanot-black">
                    Engangskode
                  </label>
                  <input
                    type="text"
                    name="code"
                    autoComplete="one-time-code"
                    className="input-text"
                    placeholder="Skriv inn her"
                    onChange={(e: any) => {
                      setFieldValue('code', e.target.value)
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setFieldTouched('code')
                      }, 300)
                    }}
                  />
                </div>
                {error ? <p>{error}</p> : null}
                <button
                  type="submit"
                  className="btn btn--primary btn--icon group"
                  style={{
                    marginTop: '12px',
                    height: '70px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                  }}
                >
                  <div className="btn__label">
                    <span>Bekreft</span>
                  </div>
                  <svg
                    className="btn__arrow"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                  </svg>
                </button>
              </Form>
            )}
          </Formik>
        </BateModal>
      ) : null}
    </>
  )
}

export default ChangePassword
