import styled from '@emotion/styled'

export const Wrapper = styled.div`
  max-width: 480px;
  margin: 0 auto;
  margin-top: 32px;
`

export const UserInformation = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0.5em;

  & > span:nth-of-type(2n) {
    margin-bottom: 1em;
  }
`
