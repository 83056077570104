import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'

const Callback: React.FC = () => {
  const authContext = useAuthContext()
  useEffect(() => {
    authContext.signinRedirectCallback()
  })
  return <span></span>
}

export default Callback
