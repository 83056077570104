import { Button, Header, Modal, Text } from '@bbl-digital/snorre'
import React, { useEffect, useState } from 'react'
import {
  ERROR_VIPPS_WRONG_CODE_HEADER,
  ERROR_VIPPS_WRONG_CODE_TEXT,
} from 'shared/constants/errorMessages'
import { LOCAL_STORAGE_VIPPS_SAVED_URL } from 'shared/constants/localStorage'

interface IProps {
  error: string
  onClose: () => void
}

const ErrorModal: React.FC<IProps> = ({ error, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [errorHeader, setErrorHeader] = useState('')

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_VIPPS_SAVED_URL)
  }, [])

  useEffect(() => {
    if (error === 'wrong_challenge') {
      setErrorMessage(ERROR_VIPPS_WRONG_CODE_TEXT)
      setErrorHeader(ERROR_VIPPS_WRONG_CODE_HEADER)
    }
  }, [error])

  return (
    <Modal
      open={true}
      onClose={onClose}
      disableOutsideClick={false}
      width="200px"
      header={<Header level={3}>{errorHeader}</Header>}
      actionButton={
        <Button outline onClick={onClose}>
          Lukk
        </Button>
      }
    >
      <Text>{errorMessage}</Text>
    </Modal>
  )
}

export default ErrorModal
