import { getFormattedDate } from '@bbl-digital/snorre'
import React from 'react'
import BateLoader from 'shared/components/BateLoader'
import { IUserRegistration } from 'shared/models/user'

interface IProps {
  user: IUserRegistration
  loading: boolean
  error: string | null
  passwordLess?: boolean
  onDone: () => void
  onCancel: () => void
  onRemoveError: () => void
}

const BateVerifyUser: React.FC<IProps> = ({
  user,
  loading,
  error,
  passwordLess,
  onDone,
  onCancel,
  onRemoveError,
}) => {
  if (loading) {
    return <BateLoader />
  }

  return (
    <section className="main-section w-full">
      <div className="container container--small">
        <div className="box">
          <h1 className="font-clanot-ultra text-red">Brukerkonto</h1>
          <p className="mb-2">
            Vi har følgende informasjon registrert om deg i vårt kunderegister.
            Vennligst bekreft at denne informasjonen er riktig.
          </p>
          <label className="text-red font-clanot-black">Navn</label>
          <p>{`${user.firstName} ${user.lastName}`}</p>

          <label className="text-red font-clanot-black">Fødelsdato</label>
          <p>
            {getFormattedDate(user.dateOfBirth, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </p>

          <label className="text-red font-clanot-black">E-post</label>
          <p>{user.email}</p>

          <label className="text-red font-clanot-black">Telefon</label>
          <p>{user.phoneNumber}</p>

          <div className="button-row" style={{ marginTop: '30px' }}>
            <button
              onClick={onDone}
              className="btn btn--primary group mr-1 mb-1"
            >
              <div className="btn__label">Bekreft</div>
              <svg
                className="btn__arrow"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
              </svg>
            </button>
            <button
              onClick={onCancel}
              className="btn btn--secondary group mb-1"
            >
              <div className="btn__label">
                <span>Avbryt</span>
              </div>
              <svg
                className="btn__arrow"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BateVerifyUser
