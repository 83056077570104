import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {
  ConfirmationCode,
  PasswordValidation,
  PhoneValidation,
} from 'shared/utils/validations'
import BateModal from 'shared/components/BateModal'
import { useState } from 'react'
import {
  API_UPDATE_PROFILE,
  API_UPDATE_PROFILE_WITH_CODE,
} from 'shared/constants/apiEndpoints'
import { useAuthContext } from 'Auth/AuthContext'
import {
  ERROR_GENERAL_ERROR,
  ERROR_FAILED_VERIFICATION,
  ERROR_WRONG_PASSWORD,
} from 'shared/constants/errorMessages'
import { getUpdateProfileErrorMessage } from 'shared/services/errorMessages'

export const PhoneSchema = Yup.object().shape({
  phone: PhoneValidation,
})

const ConfirmUserSchema = Yup.object().shape({
  code: ConfirmationCode,
  password: PasswordValidation,
})

interface IProps {
  phone: string
  onUpdateComplete: () => void
}

const PhoneField = ({ phone, onUpdateComplete }: IProps) => {
  const authContext = useAuthContext()
  const [newPhone, setNewPhone] = useState('')
  const [modal, setModal] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [submitError, setSubmitError] = useState<string | undefined>()

  const close = () => setModal(false)

  const onChangePhone = async ({ phone }: { phone: string }) => {
    try {
      await axios.put(
        API_UPDATE_PROFILE,
        { phone },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )

      setNewPhone(phone)

      setModal(true)
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errorCode) {
        setSubmitError(
          getUpdateProfileErrorMessage(err.response.data.errorCode)
        )
      } else {
        setSubmitError(ERROR_GENERAL_ERROR)
      }
    }
  }

  const onConfirm = async ({
    code,
    password,
  }: {
    code: string
    password: string
  }) => {
    try {
      const res: any = await axios.put(
        API_UPDATE_PROFILE_WITH_CODE + code,
        {
          Phone: newPhone,
          Password: password,
        },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )

      if (res.data.updatedValue) {
        onUpdateComplete()
        setModal(false)
      } else if (res.data.verificationCodeError || res.data.failedValidation) {
        setError(ERROR_FAILED_VERIFICATION)
      } else if (res.data.wrongPassword) {
        setError(ERROR_WRONG_PASSWORD)
      } else {
        setError(ERROR_GENERAL_ERROR)
      }
    } catch (err) {
      setError(ERROR_GENERAL_ERROR)
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          phone: phone,
        }}
        validationSchema={PhoneSchema}
        onSubmit={(values) => {
          onChangePhone(values)
        }}
      >
        {({ errors, setFieldValue, setFieldTouched }) => (
          <Form>
            <label className="text-red font-clanot-black">Telefon</label>
            <div className="edit-input">
              <input
                type="text"
                name="phone"
                defaultValue={phone}
                className="input-text"
                placeholder="Skriv inn her"
                onChange={(e: any) => {
                  setFieldValue('phone', e.target.value)
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldTouched('phone')
                  }, 300)
                }}
              />
              <button
                type="submit"
                className="btn-link edit-input__button"
                onClick={() => console.log(errors)}
              >
                Lagre
              </button>
            </div>
            {(errors || submitError) && (
              <p className="login-error text-red mb-1 mt-1">
                {errors?.phone || submitError}
              </p>
            )}
          </Form>
        )}
      </Formik>
      {modal ? (
        <BateModal title="Endre telefon" onClose={close}>
          <p style={{ color: '#666' }}>
            Vi har sendt deg en engangskode til {phone}. Vennligst benytt denne
            engangskoden for å bekrefte din bruker.
          </p>
          <Formik
            initialValues={{
              code: '',
              password: '',
            }}
            validationSchema={ConfirmUserSchema}
            onSubmit={(values) => {
              onConfirm(values)
            }}
          >
            {({ errors, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className="form">
                  <label className="text-red font-clanot-black">
                    Engangskode
                  </label>
                  <input
                    type="text"
                    name="code"
                    autoComplete="one-time-code"
                    className="input-text"
                    placeholder="Skriv inn her"
                    onChange={(e: any) => {
                      setFieldValue('code', e.target.value)
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setFieldTouched('code')
                      }, 300)
                    }}
                  />
                  <label className="text-red font-clanot-black">
                    Bekreft endringen med ditt passord
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="input-text"
                    placeholder="Skriv inn her"
                    onChange={(e: any) => {
                      setFieldValue('password', e.target.value)
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setFieldTouched('code')
                      }, 300)
                    }}
                  />
                </div>
                {error ? <p>{error}</p> : null}
                <button
                  type="submit"
                  className="btn btn--primary btn--icon group"
                  style={{
                    marginTop: '12px',
                    height: '70px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                  }}
                >
                  <div className="btn__label">
                    <span>Bekreft</span>
                  </div>
                  <svg
                    className="btn__arrow"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                  </svg>
                </button>
              </Form>
            )}
          </Formik>
        </BateModal>
      ) : null}
    </>
  )
}

export default PhoneField
