const formatBusinessOrMobilePhone = (phone: string) => {
  // check with match if number starts with 4 or 9
  const isMobilePhone = phone.match(/^(4|9)(\d{7})$/)

  if (isMobilePhone) {
    const [, prefix, numb] = isMobilePhone
    // Mobile phone formatting 999 88 777
    return `${prefix}${numb.slice(0, 2)} ${numb.slice(2, 4)} ${numb.slice(
      4,
      7
    )}`
  }

  // Business formatting 11 22 33 44
  return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(
    4,
    6
  )} ${phone.slice(6, 8)}`
}

const getFormatedPhone = (phone: string) => {
  const cleaned = String(phone).replace(/ /g, '')

  // Check if phone starts with 0047
  const zeros = cleaned.match(/^(0047|\+47)(\d{8})$/)
  if (zeros) {
    const [, prefix, numb] = zeros
    return `${prefix} ${formatBusinessOrMobilePhone(numb)}`
  }

  // Check if phone starts with +47
  let plus = cleaned.match(/^(\+47)(\d{3})(\d{2})(\d{3})$/)
  if (plus) {
    const [, prefix, numb] = plus
    return `${prefix} ${formatBusinessOrMobilePhone(numb)}`
  }

  if (cleaned.length === 8) return formatBusinessOrMobilePhone(cleaned)

  if (!phone) return 'Ikke angitt'

  return cleaned
}

export { getFormatedPhone }
