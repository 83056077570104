import React from 'react'
import {
  Button,
  Card,
  Header,
  Text,
  Link,
  Modal,
  ListItem,
  List,
} from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { IPerson } from 'shared/models/person'
import { getFormattedDate } from 'shared/utils/dates'
import EditEmail from '../EditEmail'
import EditPhone from '../EditPhone'
import EditPassword from '../EditPassword'
import DeleteUser from '../DeleteUser'
import ConfirmForm from '../ConfirmForm'
import { getModalHeader } from 'shared/utils/modalHeader'
import { IBbl } from 'shared/models/bbl'
import {
  CardContent,
  ConfirmWrapper,
  FormContent,
  HeaderWrapper,
  Wrapper,
} from './styles'

type ModalType =
  | 'email'
  | 'mobile'
  | 'password'
  | 'delete'
  | 'confirm-mobile'
  | 'confirm-email'
  | undefined

interface IProps {
  person: IPerson
  bbls: IBbl[]
  loading: boolean
  modal: ModalType
  confirmError: string | null
  onGoBack: () => void
  onGetUser: () => void
  onSubmitConfirm: (
    confirmtype: 'mobile' | 'email',
    values: {
      username: string
      code: string
      password: string
    }
  ) => void
  onStartConfirm: (type: 'email' | 'mobile') => void
  onSetConfirmError: (e: any) => void
  onSetModal: (type: ModalType) => void
}

const ProfileDetails: React.FC<IProps> = ({
  person,
  bbls,
  loading,
  modal,
  confirmError,
  onGoBack,
  onGetUser,
  onStartConfirm,
  onSubmitConfirm,
  onSetModal,
  onSetConfirmError,
}) => {
  const editModal = (
    <Modal
      open={modal !== undefined}
      onClose={() => {
        onSetModal(undefined)
      }}
      width="342px"
      header={
        <HeaderWrapper>
          <Header level={3}>{getModalHeader(modal)}</Header>
        </HeaderWrapper>
      }
      disableOutsideClick
    >
      {modal === 'email' && person && (
        <EditEmail
          email={person.email ? person.email : ''}
          onDone={() => {
            onGetUser()
            onSetModal(undefined)
          }}
          onCancel={() => onSetModal(undefined)}
        />
      )}
      {modal === 'mobile' && person && (
        <EditPhone
          phone={person.phoneNumber ? person.phoneNumber : ''}
          onDone={() => {
            onGetUser()
            onSetModal(undefined)
          }}
          onCancel={() => onSetModal(undefined)}
        />
      )}
      {modal === 'password' && (
        <EditPassword
          email={person.email}
          phone={person.phoneNumber}
          type={
            person.emailConfirmed
              ? person.phoneNumberConfirmed
                ? 'both'
                : 'email'
              : 'phone'
          }
          onDone={() => {
            onGetUser()
            onSetModal(undefined)
          }}
          onCancel={() => onSetModal(undefined)}
        />
      )}
      {modal === 'delete' && person && (
        <DeleteUser
          person={person}
          onDone={() => {
            onGetUser()
            onSetModal(undefined)
          }}
          onCancel={() => onSetModal(undefined)}
          bbls={bbls}
        />
      )}
      {modal === 'confirm-mobile' && person && person.phoneNumber && (
        <ConfirmForm
          confirmtype="mobile"
          username={person.phoneNumber}
          loading={loading}
          error={confirmError}
          onRemoveError={() => onSetConfirmError(null)}
          onSubmit={onSubmitConfirm}
          onCancel={() => onSetModal(undefined)}
        ></ConfirmForm>
      )}

      {modal === 'confirm-email' && person && person.email && (
        <ConfirmForm
          confirmtype="email"
          username={person.email}
          loading={loading}
          error={confirmError}
          onRemoveError={() => onSetConfirmError(null)}
          onSubmit={onSubmitConfirm}
          onCancel={() => onSetModal(undefined)}
        ></ConfirmForm>
      )}
    </Modal>
  )

  const listBbls = () => {
    const bbllist = []
    for (var x = 0; x < bbls.length; x++) {
      bbllist.push(<ListItem key={x}>{bbls[x].name}</ListItem>)
    }
    return bbllist
  }

  const connectedBblsTable = (
    <Card>
      <CardContent>
        <Text bold size="18px">
          Din bruker har tilgang til følgende boligbyggelag:
        </Text>
        <List points>{listBbls()}</List>
      </CardContent>
    </Card>
  )

  const confirmMobileWrapper = (
    <ConfirmWrapper>
      <Button
        className="confirm-contact"
        small
        outline
        onClick={() => onStartConfirm('mobile')}
		trackingName="Profile"
		trackingEvent="Details click: Bekreft mobil"
      >
        Bekreft mobil
      </Button>
    </ConfirmWrapper>
  )

  const confirmMailWrapper = (
    <ConfirmWrapper>
      <Button
        className="confirm-contact"
        small
        outline
        onClick={() => onStartConfirm('email')}
		trackingName="Profile"
		trackingEvent="Details click: Bekreft e-postadresse"
      >
        Bekreft e-postadresse
      </Button>
    </ConfirmWrapper>
  )

  return (
    <Layout>
      <Button back onClick={onGoBack}
	  trackingName="Profile"
	  trackingEvent="Details click: Tilbake">
        Tilbake
      </Button>
      {modal !== undefined && editModal}
      <Wrapper>
        <Header level={1}>Brukerkonto</Header>
        <Text>
          Brukerkontoen din benyttes som en felles pålogging for flere av
          boligbyggelagets tjenester.
        </Text>
        <Card shadow>
          <CardContent>

            <Text bold size="18px">
              {person.firstName} {person.lastName}
            </Text>
            <Text>
              {person.dateOfBirth &&
                getFormattedDate(person.dateOfBirth, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
            </Text>

            <FormContent>
              <Text semibold>E-postadresse</Text>
              <Text>{person.email}</Text>
              <Link onClick={() => onSetModal('email')}>Endre</Link>
              {person.email && !person.emailConfirmed && confirmMailWrapper}

              <Text semibold>Mobilnummer</Text>
              <Text>{person.phoneNumber}</Text>
              <Link onClick={() => onSetModal('mobile')}>Endre</Link>
              {person.phoneNumber &&
                !person.phoneNumberConfirmed &&
                confirmMobileWrapper}

              <Text semibold>Passord</Text>
              <Text>********************</Text>
              <Link onClick={() => onSetModal('password')}>Endre</Link>
            </FormContent>

            <Link danger onClick={() => onSetModal('delete')}>
              Slett din bruker
            </Link>
          </CardContent>
        </Card>
        {bbls.length > 1 ? connectedBblsTable : null}
      </Wrapper>
    </Layout>
  )
}

export default ProfileDetails
