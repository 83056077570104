import React from 'react'
import { Text, Button, Link } from '@bbl-digital/snorre'
import { ButtonWrapper } from './styles'
import { IBbl } from 'shared/models/bbl'
import { IAppContext } from 'App'

interface IProps {
  appContext: IAppContext
  bbl: IBbl | null
  isRequestLoading: boolean
  isRequestSent: boolean
  onSendRequest: () => void
  onLogout: () => void
}

const NoAccessActions: React.FC<IProps> = ({
  appContext,
  bbl,
  isRequestLoading,
  isRequestSent,
  onSendRequest,
  onLogout,
}) => {
  return (
    <ButtonWrapper>
      <Button
        loading={isRequestLoading}
        highlight
        onClick={onSendRequest}
        disabled={isRequestSent}
        trackingName="No access"
        trackingEvent="No access actions click: Send forespørsel"
      >
        Send forespørsel
      </Button>
      {(appContext.returnUrl || appContext.originReturnUrl) && (
        <Button
          highlight
          outline
          onClick={onLogout}
          trackingName="No access"
          trackingEvent="No access actions click: Logg ut"
        >
          Logg ut
        </Button>
      )}
      <Link
        href={`/profil?bbl=${appContext.bbl}&returnUrl=${appContext.originReturnUrl}`}
      >
        Min brukerprofil
      </Link>

      <Text subtle>
        Du kan ringe oss på {bbl?.phone} for en raskere behandling, men vi
        oppfordrer deg uansett til å trykke på knappen over, slik at vi kan se
        hva du har oppgitt som dine brukerdetaljer.
      </Text>
    </ButtonWrapper>
  )
}

export default NoAccessActions
