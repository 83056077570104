import React, { Fragment, useContext, useState } from 'react'
import { Card, Alert } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { fetchUser, sendRequestToBbl } from 'shared/api/user'
import { Wrapper } from './styles'
import { useAuthContext } from 'Auth/AuthContext'
import { useEffect } from 'react'
import { IPerson } from 'shared/models/person'
import PageLoader from 'shared/components/PageLoader'
import { AppContext } from 'App'
import { USER_BBL_REQUEST_SENT } from 'shared/constants/messages'
import {
  ERROR_USER_ACCESS_REQUEST_FAILED,
  ERROR_USER_GET_FAILED,
} from 'shared/constants/errorMessages'
import { IBbl } from 'shared/models/bbl'
import { fetchBbl } from 'shared/api/bbl'
import NoAccessInformation from './NoAccessInformation'
import NoAccessActions from './NoAccessActions'
import BateNoAccess from 'Modules/Bate/NoAccess'
import useBrandingTheme from 'shared/utils/useBrandingTheme'
import BateLoader from 'shared/components/BateLoader'

interface IProps {}

const NoAccess: React.FC<IProps> = () => {
  const appContext = useContext(AppContext)
  const authContext = useAuthContext()
  const [bbl, setBbl] = useState<IBbl | null>(null)
  const [person, setPerson] = useState<IPerson | null>(null)
  const [loading, setLoading] = useState(true)
  const [requestLoading, setRequestLoading] = useState(false)
  const [requestMessage, setRequestMessage] = useState<string | undefined>(
    undefined
  )
  const [error, setError] = useState<string | null>(null)
  const { brandingTheme } = useBrandingTheme()

  useEffect(() => {
    fetchBbl()
      .then((res: IBbl) => {
        setBbl(res)
      })
      .catch((err) => {
        setBbl(null)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      authContext.createSigninRequest().then(() => {
        authContext.signinRedirect()
      })
    } else {
      getUser()
    }
  }, [authContext])

  const getUser = () => {
    const token = authContext.getToken()
    fetchUser(token)
      .then((res) => {
        setPerson(res.data)
      })
      .catch((err) => {
        setError(ERROR_USER_GET_FAILED)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSendRequest = async () => {
    setRequestLoading(true)

    try {
      await sendRequestToBbl()
      setRequestMessage(USER_BBL_REQUEST_SENT)
    } catch (_) {
      setError(ERROR_USER_ACCESS_REQUEST_FAILED)
    } finally {
      setRequestLoading(false)
    }
  }

  const onLogout = () => {
    if (appContext.returnUrl) {
      window.location.href = appContext.returnUrl
    }
    if (appContext.originReturnUrl) {
      window.location.href = appContext.originReturnUrl
    }
  }

  if (brandingTheme === 'bate')
    return loading ? (
      <BateLoader />
    ) : (
      <BateNoAccess
        loading={loading}
        requestMessage={requestMessage}
        onSendRequest={onSendRequest}
        onLogout={onLogout}
      />
    )

  const Content = (
    <Fragment>
      <NoAccessInformation person={person} bbl={bbl} />
      {requestMessage && (
        <Alert success onClose={() => setRequestMessage(undefined)}>
          {requestMessage}
        </Alert>
      )}
      <NoAccessActions
        bbl={bbl}
        appContext={appContext}
        isRequestLoading={requestLoading}
        isRequestSent={!!requestMessage}
        onSendRequest={onSendRequest}
        onLogout={onLogout}
      />
    </Fragment>
  )

  return loading ? (
    <PageLoader />
  ) : (
    <Layout>
      <Wrapper>
        <Card shadow largePadding>
          {error ? <Alert danger>{error}</Alert> : Content}
        </Card>
      </Wrapper>
    </Layout>
  )
}

export default NoAccess
