import React from 'react'
import { Button, Text, Card } from '@bbl-digital/snorre'
import { CardContent } from '../../../shared/components/CardContent'
import { Wrapper } from './styles'

interface IProps {
  message: string
  buttonText: string
  onButtonClick: () => void
}

const NewPasswordResult: React.FC<IProps> = ({
  message,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Wrapper>
      <Card shadow>
        <CardContent>
          <Text>{message}</Text>
          <Button highlight onClick={onButtonClick}
		  trackingName="Forgot password"
		  trackingEvent="New password result click: Button">
            {buttonText}
          </Button>
        </CardContent>
      </Card>
    </Wrapper>
  )
}

export default NewPasswordResult
