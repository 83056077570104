import axios from 'axios'
import {
  API_CONFIRM_USER,
  API_CREATE_USER,
  API_GET_USER,
  API_SEND_REQUEST,
  API_VERIFY_USER,
} from 'shared/constants/apiEndpoints'
import { removeSpaces } from 'shared/utils/string'

const fetchUser = (token: string) => {
  return axios.get(API_GET_USER, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

const sendRequestToBbl = () => {
  return axios.get(API_SEND_REQUEST)
}

const createUser = (values: any) => {
  return axios.post(API_CREATE_USER, {
    EmailOrPhone: removeSpaces(values.username),
    ...(values.birthDate && { DateOfBirth: values.birthDate }),
    Password: values.password,
  })
}

const createUserSubmitCode = (
  values: any,
  password: string | undefined,
  dateOfBirth?: string | null
) => {
  return axios.post(API_CONFIRM_USER, {
    EmailOrPhone: values.username,
    Password: password,
    ...(dateOfBirth && { DateOfBirth: dateOfBirth }),
    VerificationCode: values.code,
  })
}

const createUserVerify = (
  username: string | undefined,
  password: string | undefined,
  verificationCode: string | undefined,
  dateOfBirth?: string | null
) => {
  return axios.post(API_VERIFY_USER, {
    EmailOrPhone: username,
    Password: password,
    ...(dateOfBirth && { DateOfBirth: dateOfBirth }),
    VerificationCode: verificationCode,
  })
}

export {
  fetchUser,
  sendRequestToBbl,
  createUser,
  createUserSubmitCode,
  createUserVerify,
}
