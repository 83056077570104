import styled from '@emotion/styled'

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  font-family: 'Source Sans Pro', sans-serif;

  & > div {
    margin-bottom: 3em;
  }
`
