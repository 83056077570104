import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-top: 1.5em;

    a {
      font-size: 20px;
      font-weight: normal;
    }
  }
`
