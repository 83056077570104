import React, { useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { Button, Text, Alert } from '@bbl-digital/snorre'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { EditWrapper } from '../styles'
import { PasswordValidation } from 'shared/utils/validations'
import { useAuthContext } from 'Auth/AuthContext'
import { getUpdateProfileErrorMessage } from 'shared/services/errorMessages'
import { API_UPDATE_PASSWORD } from 'shared/constants/apiEndpoints'
import {
  ERROR_GENERAL_ERROR,
  ERROR_INVALID_PASSWORD_FORMAT,
  ERROR_WRONG_EXSISTING_PASSWORD,
  ERROR_WRONG_ONE_TIME_CODE,
} from 'shared/constants/errorMessages'
import { UpdateProfileEnum } from 'shared/models/enums'
import PasswordField from 'shared/components/PasswordField'
import ConfirmForm from './ConfirmForm'

interface IProps {
  email: string | undefined
  phone: string | undefined
  type: 'phone' | 'email' | 'both'
  onDone: () => void
  onCancel: () => void
}

const EditPasswordSchema = Yup.object().shape({
  password: PasswordValidation,
  passwordConfirm: PasswordValidation,
})

enum Steps {
  Input,
  Code,
}

const EditPassword: React.FC<IProps> = ({
  email,
  phone,
  type,
  onDone,
  onCancel,
}) => {
  const [step, setStep] = useState(Steps.Input)
  const authContext = useAuthContext()
  const [password, setPassword] = useState<string | undefined>()
  const [username, setUsername] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const isPasswordEqual = (values: {
    password: string
    passwordConfirm: string
  }) => values.password === values.passwordConfirm

  const submitForm = (values: {
    password: string
    passwordConfirm: string
  }) => {
    if (!isPasswordEqual(values)) {
      setError('Passord og Bekreft passord er ikke like.')
      return
    }

    setPassword(values.password)

    setLoading(true)

    axios
      .put(
        API_UPDATE_PASSWORD,
        {
          Code: undefined,
          NewPassword: values.password,
          EmailOrPhone: username,
        },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )
      .then((res: AxiosResponse) => {
        if (res.data.errorCode === UpdateProfileEnum.FailedValidation) {
          setError(ERROR_INVALID_PASSWORD_FORMAT)
        } else if (res.data.wrongPassword) {
          setError(ERROR_WRONG_EXSISTING_PASSWORD)
        } else {
          setPassword(values.password)
          setStep(Steps.Code)
        }
      })
      .catch((err) => {
        setError(getUpdateProfileErrorMessage(err.response.data.code))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitCode = (code: string) => {
    setLoading(true)

    axios
      .put(
        API_UPDATE_PASSWORD,
        {
          Code: code,
          NewPassword: password,
          EmailOrPhone: username,
        },
        {
          headers: { Authorization: 'Bearer ' + authContext.getToken() },
        }
      )
      .then((res: AxiosResponse) => {
        if (res.data.errorCode === UpdateProfileEnum.FailedValidation) {
          setError(ERROR_INVALID_PASSWORD_FORMAT)
        } else if (res.data.wrongPassword) {
          setError(ERROR_WRONG_EXSISTING_PASSWORD)
        } else if (res.data.verificationCodeError) {
          setError(ERROR_WRONG_ONE_TIME_CODE)
        } else if (res.data.updatedValue) {
          onDone()
        } else {
          setError(ERROR_GENERAL_ERROR)
        }
      })
      .catch((err) => {
        setError(getUpdateProfileErrorMessage(err.response.data.code))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <EditWrapper>
      {step === Steps.Input && (
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validationSchema={EditPasswordSchema}
          onSubmit={(values) => {
            submitForm(values)
          }}
        >
          {({ errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <PasswordField
                name="password"
                label="Nytt passord"
                validation={true}
                invalidMessage={
                  errors.password && touched.password ? errors.password : null
                }
                onChange={(e: any) => {
                  setError(null)
                  setFieldValue('password', e.target.value)
                  setTimeout(() => setFieldTouched('password'))
                }}
              />

              <PasswordField
                name="passwordConfirm"
                label="Gjenta passord"
                validation={true}
                invalidMessage={
                  errors.passwordConfirm && touched.passwordConfirm
                    ? errors.passwordConfirm
                    : null
                }
                onChange={(e: any) => {
                  setError(null)
                  setFieldValue('passwordConfirm', e.target.value)
                  setTimeout(() => setFieldTouched('passwordConfirm'))
                }}
              />

              <Text subtle>
                Passordet må ha minst 8 tegn. Må inkludere store og små
                bokstaver, samt tall.
              </Text>

              {error && <Alert danger>{error}</Alert>}

              {type !== 'email' && (
                <Button
                  type="submit"
                  onClick={(e) => {
                    setUsername(phone)
                  }}
                  loading={loading}
				  trackingName="Profile"
				  trackingEvent="Edit password details click: Bekreft med sms"
                >
                  Bekreft med sms
                </Button>
              )}
              {type !== 'phone' && (
                <Button
                  type="submit"
                  onClick={(e) => {
                    setUsername(email)
                  }}
                  loading={loading}
				  trackingName="Profile"
				  trackingEvent="Edit password details click: Bekreft med e-post"
                >
                  Bekreft med e-post
                </Button>
              )}
              <Button outline onClick={onCancel}
			  trackingName="Profile"
			  trackingEvent="Edit password details click: Avbryt"
			  >
                Avbryt
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {step === Steps.Code && (
        <ConfirmForm
          username={username}
          error={error}
          loading={loading}
          onRemoveError={() => setError(null)}
          onSubmit={(values) => submitCode(values.code)}
        />
      )}
    </EditWrapper>
  )
}

export default EditPassword
