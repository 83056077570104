import React, { useState, useRef, useEffect } from 'react'
import { Button, Header, Link, Alert, Text, Html } from '@bbl-digital/snorre'
import {
  ActionWrapper,
  Container,
  DigitBox,
  errorStyle,
  FormWrapper,
  KnutWrapper2,
} from '../styles'
import { Formik, Form } from 'formik'
import { useTheme } from '@emotion/react'

interface IProps {
  username: string
  loading: boolean
  error: string | null
  onSubmit: (values: { code: string }) => void
  onBack: () => void
  onClearError: () => void
}

const LoginWithCode: React.FC<IProps> = ({
  username,
  loading,
  error,
  onSubmit,
  onBack,
  onClearError,
}) => {
  const theme: any = useTheme()
  const [focus, setFocus] = useState(false)
  const [code, setCode] = useState('')
  const [validationError, setValidationError] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (code.length === 4) {
      submit({ code })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  const onErrorRemove = () => {
    clearAndFocus()
    onClearError()
  }

  const clearAndFocus = () => {
    setCode('')
    inputRef.current?.focus()
  }

  const submit = (values: { code: string }) => {
    if (values.code.length < 4) {
      setValidationError('Ugyldig kode')
    } else {
      onSubmit(values)
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          code: '',
        }}
        onSubmit={(values) => {
          submit(values)
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormWrapper>
              <Header level={1}>Kode sendt</Header>
              <Text paragraph>
                Skriv inn koden
                <br />
                som ble sendt til <strong>{username}</strong>
              </Text>
              <KnutWrapper2>
                <input
                  autoComplete="off"
                  id="code"
                  ref={inputRef}
                  type="text"
                  value={code}
                  autoFocus
                  maxLength={4}
                  pattern="[0-9]+"
                  onChange={() => {}}
                  onInput={(e) => {
                    setFieldValue('code', e.currentTarget.value)
                    setCode(e.currentTarget.value)
                    onClearError()
                    setValidationError(undefined)

                    // Fix for android
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />

                <DigitBox
                  borderColor={theme.border}
                  primary={theme.primary}
                  focus={code.length === 0 && focus}
                  onClick={clearAndFocus}
                >
                  {!!code[0] && code[0]}
                </DigitBox>
                <DigitBox
                  borderColor={theme.border}
                  primary={theme.primary}
                  focus={code.length === 1 && focus}
                  onClick={clearAndFocus}
                >
                  {!!code[1] && code[1]}
                </DigitBox>
                <DigitBox
                  borderColor={theme.border}
                  primary={theme.primary}
                  focus={code.length === 2 && focus}
                  onClick={clearAndFocus}
                >
                  {!!code[2] && code[2]}
                </DigitBox>
                <DigitBox
                  borderColor={theme.border}
                  primary={theme.primary}
                  focus={code.length > 2 && focus}
                  onClick={clearAndFocus}
                >
                  {!!code[3] && code[3]}
                </DigitBox>
              </KnutWrapper2>
              {error && (
                <Alert danger onClose={onErrorRemove} css={errorStyle}>
                  <Html text={error} />
                </Alert>
              )}
              {validationError && (
                <Alert danger onClose={() => setValidationError(undefined)}>
                  {validationError}
                </Alert>
              )}

              <ActionWrapper>
                <Button type="submit" loading={loading} highlight
				trackingName="Login"
				trackingEvent="Login code click: Bekreft">
                  Bekreft
                </Button>
                <Link onClick={onBack}>Tilbake</Link>
              </ActionWrapper>
            </FormWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default LoginWithCode
