import { AppLoader } from '@bbl-digital/snorre'
import React from 'react'
import { AppLoaderWrapper } from './styles'

interface IProps {}

const PageLoader: React.FC<IProps> = (props) => {
  return (
    <AppLoaderWrapper>
      <AppLoader />
    </AppLoaderWrapper>
  )
}

export default PageLoader
