import axios from 'axios'
import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { IDENTITY_CONFIG } from 'shared/constants/authConstants'

export default class AuthService {
  UserManager: UserManager

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    })
    // Logger
    Log.logger = console
    Log.level = Log.INFO
    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })
    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message)
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      this.UserManager.removeUser()
      if (
        window.location.pathname.indexOf('profil') !== -1 ||
        window.location.pathname.indexOf('ikketilgang') !== -1
      ) {
        this.signinRedirect()
      }
    })
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      const redirectUri = sessionStorage.getItem('redirectUri')
      window.location.replace(redirectUri ? redirectUri : '/')
    })
  }

  getUser = async () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.getToken()
    const user = await this.UserManager.getUser()
    if (!user) {
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  signinRedirect = () => {
    sessionStorage.setItem(
      'redirectUri',
      window.location.pathname + window.location.search
    )
    let args: any = {
      acr_values: window.config.bblCode,
    }
    this.UserManager.signinRedirect(args)
  }

  navigateToScreen = () => {
    window.location.replace('')
  }

  isAuthenticated = () => {
    const fromStorage = sessionStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
    )
    const oidcStorage = JSON.parse(fromStorage ? fromStorage : '{}')
    const expires = oidcStorage.expires_at * 1000
    const now = new Date().getTime()
    return !!oidcStorage && !!oidcStorage.access_token && expires > now
  }

  getToken = () => {
    const fromStorage = sessionStorage.getItem(
      `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
    )
    const oidcStorage = JSON.parse(fromStorage ? fromStorage : '{}')
    return oidcStorage.access_token
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user)
      })
      .catch((err) => {
        console.log(err)
        this.signinRedirect()
      })
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    sessionStorage.clear()
    return this.UserManager.createSigninRequest()
  }

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: sessionStorage.getItem('id_token'),
    })
    this.UserManager.clearStaleState()
  }

  logoutGateway = () => {
    sessionStorage.clear()

    const params = new URLSearchParams(window.location.search)
    const returnurl = params.get('returnurl')

    if (returnurl)
      window.location.href =
        returnurl?.slice(0, returnurl.indexOf('profil')) + 'logout'
    else
      window.location.href =
        window.config.bblidGatewayUrl + '/host/core/connect/endsession'
  }

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      sessionStorage.clear()
      window.location.replace(window.config.baseUrl)
    })
    this.UserManager.clearStaleState()
  }
}
