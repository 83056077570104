import { LOCAL_STORAGE_VIPPS_SAVED_URL } from 'shared/constants/localStorage'

const params: any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => searchParams.get(prop),
})

export const handleError = () => {
  const redirectUrl = localStorage.getItem(LOCAL_STORAGE_VIPPS_SAVED_URL)
  const err = params.error
  let url = redirectUrl || ''

  if (err !== 'access_denied') {
    url += '&error=' + params.error
  }

  window.location.href = url
}
