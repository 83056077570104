import { getFormattedDate } from '@bbl-digital/snorre'
import React, { useState } from 'react'
import BateLoader from 'shared/components/BateLoader'
import BateModal from 'shared/components/BateModal'
import { IPerson } from 'shared/models/person'
import ChangePassword from './ChangePassword'
import DeleteUser from './DeleteUser'
import EmailField from './EmailField'
import PhoneField from './PhoneField'

interface IProps {
  person: IPerson
  loading: boolean
  onUpdateComplete: () => void
  onGoBack: () => void
}

type Modals = 'email' | 'password' | 'phone'

const BateUserDetails = ({
  person,
  loading,
  onUpdateComplete,
  onGoBack,
}: IProps) => {
  const [updateCompleted, setUpdateCompleted] = useState(false)
  const [updatedMessage, setUpdatedMessage] = useState('')

  const messages: Record<string, string> = {
    email: 'E-postadressen din er endret!',
    phone: 'Telefonnummeret ditt er endret!',
    password: 'Passordet ditt er endret!',
  }
  const handleComplete = (type: 'phone' | 'email' | 'password') => {
    setUpdateCompleted(true)
    setUpdatedMessage(messages[type])
    onUpdateComplete?.()
  }
  return (
    <>
      {/* <BateModal title="Endre e-postadresse">hi</BateModal> */}
      {loading ? <BateLoader /> : null}
      <section className="main-section w-full">
        <div className="container container--small">
          <div className="box">
            <button onClick={onGoBack} className="btn-link mb-2 w-fit">
              Tilbake
            </button>
            <h1 className="font-clanot-ultra text-red">
              Endre påloggingsdetaljer
            </h1>
            <p className="mb-2">
              Endre e-postadresse, telefonnummer eller passord og trykk "Lagre".
            </p>
            <p className="text-lg text-red m-0">{`${person.firstName} ${person.lastName}`}</p>
            <p>
              {getFormattedDate(person.dateOfBirth, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </p>
            <div className="form">
              <EmailField
                email={person.email}
                onUpdateComplete={() => handleComplete('email')}
              />
            </div>

            <div className="form">
              <PhoneField
                phone={person.phoneNumber}
                onUpdateComplete={() => handleComplete('phone')}
              />
            </div>

            <div className="form">
              <ChangePassword
                username={
                  person.emailConfirmed ? person.email : person.phoneNumber
                }
                onDone={() => handleComplete('password')}
              />
            </div>
            {updateCompleted && (
              <div className="form">
                <p className="text-red mb-1 mt-1">{updatedMessage}</p>
              </div>
            )}
            <div className="form">
              <DeleteUser person={person} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BateUserDetails
