import React from 'react'
import styled from '@emotion/styled'
import bp from '@bbl-digital/snorre/lib/globals/styles/breakpoints'
import { styles } from 'shared/constants/styles'

const Wrapper = styled.div<IProps>`
  padding-top: 1em;
  max-width: ${(props) => (props.fullsize ? 'none' : styles.maxWidth)};
  margin: 0 auto;

  @media screen and (max-width: ${bp.xlarge}) {
    padding: 1em 1em;
  }
`

interface IProps {
  fullsize?: boolean,
  children?: any
}

const Layout: React.FC<IProps> = (props) => {
  return <Wrapper fullsize={props.fullsize}>{props.children}</Wrapper>
}

export default Layout
