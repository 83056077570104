export const getFormattedDate = (date: string | Date, opt?: any) => {
  const options = opt || {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }
  return new Intl.DateTimeFormat(['no-NO', 'nb-NO', 'nn-NO'], options).format(
    new Date(date)
  )
}

export const getParsedDate = (date: string | Date) => {
  const parsedDate = new Date(date)
  parsedDate.setHours(12)
  return parsedDate
}
