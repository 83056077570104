import React, { useState, useEffect, Fragment, useContext } from 'react'
import axios, { AxiosResponse } from 'axios'
import { Button, Alert } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import PageLoader from 'shared/components/PageLoader'
import { IPerson } from 'shared/models/person'
import { AlertWrapper } from './styles'
import { useAuthContext } from 'Auth/AuthContext'
import {
  API_GET_USER_BBLS,
  API_UPDATE_PROFILE,
  API_UPDATE_PROFILE_WITH_CODE,
} from 'shared/constants/apiEndpoints'
import { IBbl } from 'shared/models/bbl'
import {
  ERROR_FAILED_VERIFICATION,
  ERROR_GENERAL_ERROR,
  ERROR_WRONG_PASSWORD,
} from 'shared/constants/errorMessages'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'App'
import { fetchUser } from 'shared/api/user'
import DetailsContent from './DetailsContent'
import useBrandingTheme from 'shared/utils/useBrandingTheme'
import BateUserDetails from 'Modules/Bate/UserDetails'

interface IProps {}

const ProfileDetails: React.FC<IProps> = () => {
  const authContext = useAuthContext()
  const appContext = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [confirmError, setConfirmError] = useState<string | null>(null)
  const [modal, setModal] = useState<
    | 'email'
    | 'mobile'
    | 'password'
    | 'delete'
    | 'confirm-mobile'
    | 'confirm-email'
    | undefined
  >()
  const { brandingTheme } = useBrandingTheme()

  const [person, setPerson] = useState<IPerson | null>(null)
  const [bbls, setBbls] = useState<IBbl[]>([])
  const history = useHistory()

  const goBack = () => {
    if (appContext.originReturnUrl) {
      window.location.href = appContext.originReturnUrl
    } else {
      history.goBack()
    }
  }

  const logout = () => {
    authContext.logout()
  }

  const getUser = () => {
    if (!loading) setLoading(true)
    const token = authContext.getToken()
    fetchUser(token)
      .then((res) => {
        setPerson(res.data)

        getConnectedBbls()
      })
      .catch((err) => {
        // Send to login if unauthorized
        if (err.response.status === 401) authContext.signinRedirect()

        setError(
          'Feil under henting av bruker. Logg inn på nytt. \nOm problemet vedvarer kontakt ditt boligbyggelag.'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getConnectedBbls = () => {
    setLoading(true)
    axios
      .get(API_GET_USER_BBLS, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
      })
      .then((res) => {
        setBbls(res.data as IBbl[])
      })
      .catch((err) => {
        console.log('Feil under henting av tilkoblede bbls', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const startConfirm = (confirmtype: 'mobile' | 'email', email?: string) => {
    let model: any
    switch (confirmtype) {
      case 'mobile':
        setModal('confirm-mobile')
        model = { Phone: person?.phoneNumber }
        break
      case 'email':
        setModal('confirm-email')
        model = { Email: person?.email }
        break
    }

    axios
      .put(API_UPDATE_PROFILE, model, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
      })
      .then((res: AxiosResponse) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const submitConfirm = (
    confirmtype: 'email' | 'mobile',
    values: {
      username: string
      code: string
      password: string
    }
  ) => {
    setLoading(true)
    let model: any
    switch (confirmtype) {
      case 'email':
        model = { Email: values.username, Password: values.password }
        break
      case 'mobile':
        model = { Phone: values.username, Password: values.password }
        break
    }

    axios
      .put(API_UPDATE_PROFILE_WITH_CODE + values.code, model, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
      })
      .then((res: AxiosResponse) => {
        if (res.data.updatedValue) {
          getUser()
          setModal(undefined)
        } else if (
          res.data.verificationCodeError ||
          res.data.failedValidation
        ) {
          setConfirmError(ERROR_FAILED_VERIFICATION)
        } else if (res.data.wrongPassword) {
          setConfirmError(ERROR_WRONG_PASSWORD)
        } else {
          setConfirmError(ERROR_GENERAL_ERROR)
        }
      })
      .catch((err) => {
        setConfirmError(ERROR_GENERAL_ERROR)
        getUser()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Loader = <PageLoader />

  const Error = (
    <Layout>
      <AlertWrapper>
        <Alert danger>{error}</Alert>
        <Button
          outline
          onClick={logout}
          trackingName="Profile"
          trackingEvent="Details click: Logg ut"
        >
          Logg ut
        </Button>
      </AlertWrapper>
    </Layout>
  )

  if (brandingTheme === 'bate' && person)
    return (
      <BateUserDetails
        person={person}
        loading={loading}
        onUpdateComplete={getUser}
        onGoBack={goBack}
      />
    )

  return (
    <Fragment>
      {person && (
        <DetailsContent
          person={person}
          bbls={bbls}
          modal={modal}
          loading={loading}
          confirmError={confirmError}
          onGoBack={goBack}
          onGetUser={getUser}
          onSubmitConfirm={submitConfirm}
          onStartConfirm={startConfirm}
          onSetModal={setModal}
          onSetConfirmError={setConfirmError}
        />
      )}
      {loading && Loader}
      {error && Error}
    </Fragment>
  )
}

export default ProfileDetails
