import React from 'react'
import { Alert, Button, Input } from '@bbl-digital/snorre'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { EmailValidation } from 'shared/utils/validations'

interface IProps {
  email: string
  loading: boolean
  error: string | null
  onSubmit: (values: { email: string }) => void
  onCancel: () => void
  onRemoveError: () => void
}

const EditEmailSchema = Yup.object().shape({
  email: EmailValidation,
})

const EmailForm: React.FC<IProps> = ({
  email,
  loading,
  error,
  onSubmit,
  onCancel,
  onRemoveError,
}) => {
  return (
    <Formik
      initialValues={{
        email,
      }}
      validationSchema={EditEmailSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Input
            type="text"
            label="E-post"
            value={email}
            validation={true}
            invalidMessage={errors.email && touched.email ? errors.email : null}
            onChange={(e: any) => {
              onRemoveError()
              setFieldValue('email', e.target.value)
            }}
            onBlur={() => setTimeout(() => setFieldTouched('email'))}
          />
          {error && <Alert danger>{error}</Alert>}
          <Button type="submit" loading={loading}
		  trackingName="Profile"
		  trackingEvent="Edit email details click: Lagre">
            Lagre
          </Button>
          <Button outline onClick={onCancel}
		  trackingName="Profile"
		  trackingEvent="Edit email details click: Avbryt">
            Avbryt
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default EmailForm
