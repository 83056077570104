import React from 'react'
import BateLoader from 'shared/components/BateLoader'

interface IProps {
  loading: boolean
  requestMessage: string | undefined
  onSendRequest: () => void
  onLogout: () => void
}

const BateNoAccess = ({
  loading,
  requestMessage,
  onSendRequest,
  onLogout,
}: IProps) => {
  return (
    <section className="main-section w-full">
      {loading && <BateLoader />}
      <div className="container container--small">
        <div className="box">
          <h1 className="font-clanot-ultra text-red">Beklager!</h1>

          <p style={{ marginBottom: '30px' }}>
            Vi klarte dessverre ikke å finne deg i vårt system. Bates
            kundeservice er kjapt på saken. Det er bare å trykke på knappen
            under, så får vi kontaktinformasjonen din, og hjelper deg videre i
            løpet av neste virkedag.
          </p>

          {requestMessage && <p>{requestMessage}</p>}

          <div className="button-row">
            <button
              onClick={onSendRequest}
              disabled={!!requestMessage}
              className="btn btn--primary btn--icon group"
              style={{ marginRight: '20px' }}
            >
              <div className="btn__label">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/bate/gfx/Bate__dame_med_mikrofon.png'
                  }
                  alt=""
                />
                <span>Send til kundeservice</span>
              </div>
              <svg
                className="btn__arrow"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
              </svg>
            </button>
            <button onClick={onLogout} className="btn btn--secondary group">
              <div className="btn__label">
                <span>Logg ut</span>
              </div>
              <svg
                className="btn__arrow"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BateNoAccess
