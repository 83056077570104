import React from 'react'
import { Input, Button, Text, Alert } from '@bbl-digital/snorre'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ConfirmationCode } from 'shared/utils/validations'

interface IProps {
  username: string | undefined
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onSubmit: (values: { code: string }) => void
}

const ConfirmUserSchema = Yup.object().shape({
  code: ConfirmationCode
})

const ConfirmForm: React.FC<IProps> = ({
  username,
  loading,
  error,
  onSubmit,
  onRemoveError,
}) => {
  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={ConfirmUserSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Text paragraph>
            Vi har sendt deg en engangskode til {username}. Vennligst benytt
            denne engangskoden for å bekrefte sletting av bruker.
          </Text>

          <Input
            type="text"
            label="Engangskode"
            focus
            validation={true}
            invalidMessage={errors.code && touched.code ? errors.code : null}
            onChange={(e: any) => {
              setFieldValue('code', e.target.value)
              onRemoveError()
              setTimeout(() => setFieldTouched('code'))
            }}
          />

          {error && (
            <Alert danger onClose={() => onRemoveError()}>
              {error}
            </Alert>
          )}

          <Button highlight type="submit" loading={loading}
		  	trackingName="Profile"
		  	trackingEvent="Delete user details click: Bekreft">
            Bekreft
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ConfirmForm
