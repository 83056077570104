import { Form, Formik } from 'formik'
import React from 'react'
import BateLoader from 'shared/components/BateLoader'
import { IBbl } from 'shared/models/bbl'
import { PasswordValidation, UserValidation } from 'shared/utils/validations'
import * as Yup from 'yup'

const CreateUserSchema = Yup.object().shape({
  username: UserValidation,
  password: PasswordValidation,
})

interface IProps {
  bbl: IBbl | null
  username: string | undefined
  showDatepicker: boolean
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onSubmit: ({
    username,
    password,
    birthDate,
  }: {
    username: string
    password: string
    birthDate: string | null
  }) => void
  onSubmitCode: ({ username, code }: { username: string; code: string }) => void
  onGoBack: () => void
}

function BateCreateUser({
  username,
  showDatepicker,
  loading,
  error,
  onRemoveError,
  onSubmit,
  onSubmitCode,
  onGoBack,
}: IProps) {
  return (
    <section className="main-section w-full">
      {loading && <BateLoader />}
      <div className="container container--flex">
        <div className="w-full">
          <div className="section-content">
            <h2 className="h1 font-clanot-ultra text-red">Hei</h2>
            <p className="mb-2">
              Her oppgir du samme e-post eller mobilnummer du brukte da du ble
              medlem. Har du glemt det, eller vil ha hjelp til å lage en bruker
              på Min side, er det bare å ta kontakt med kundesenteret vårt.
            </p>
            <p className="mb-2">
              Du når oss på telefon 51 84 95 00 eller på e-post til
              kundeservice@bate.no alle hverdager fra 9 - 15.
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="box">
            <div className="box__icon">
              <img
                src={
                  process.env.PUBLIC_URL + '/bate/gfx/forfatter_publiserer.png'
                }
                alt=""
                className="box__icon__image  box__icon__image--person"
              />
            </div>
            {!username && (
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  birthDate: null,
                }}
                validationSchema={CreateUserSchema}
                onSubmit={(values) => {
                  onSubmit(values)
                }}
              >
                {({ errors, touched, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <h1 className="font-clanot-ultra text-red">
                      Lag ny bruker
                    </h1>
                    <div className="form mb-2">
                      <label className="text-red font-clanot-black">
                        E-post eller mobilnummer
                      </label>
                      <input
                        name="username"
                        type="text"
                        className="input-text"
                        placeholder="Skriv inn her"
                        onChange={(e: any) => {
                          setFieldValue('username', e.target.value)
                          onRemoveError()
                          setTimeout(() => setFieldTouched('username'))
                        }}
                      />
                      <label className="text-red font-clanot-black">
                        Ønsket passord
                      </label>
                      <input
                        name="password"
                        type="password"
                        className="input-text mb-1"
                        placeholder="Skriv inn her"
                        onChange={(e: any) => {
                          setFieldValue('password', e.target.value)
                          onRemoveError()
                          setTimeout(() => setFieldTouched('password'))
                        }}
                      />

                      <p className="mb-2">
                        Passordet må ha minst åtte tegn, inkludert store og små
                        bokstaver og minst ett tall.
                      </p>
                    </div>
                    {error && (
                      <p className="login-error text-red mb-1">{error}</p>
                    )}
                    <div className="button-row">
                      <button
                        type="submit"
                        className="btn btn--primary group mr-1 mb-1"
                      >
                        <div className="btn__label">Bekreft</div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={onGoBack}
                        className="btn btn--secondary group mb-1"
                      >
                        <div className="btn__label">
                          <span>Avbryt</span>
                        </div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {username && (
              <Formik
                initialValues={{
                  username: username,
                  code: '',
                }}
                onSubmit={(values) => {
                  onSubmitCode(values)
                }}
              >
                {({ errors, touched, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <h1 className="font-clanot-ultra text-red">
                      Lag ny bruker
                    </h1>
                    <div className="form mb-2">
                      <label className="text-red font-clanot-black">
                        Engangskode
                      </label>
                      <input
                        name="code"
                        type="text"
                        autoComplete="off"
                        className="input-text"
                        placeholder="Skriv inn her"
                        onChange={(e: any) => {
                          setFieldValue('code', e.target.value)
                          onRemoveError()
                          setTimeout(() => setFieldTouched('code'))
                        }}
                      />
                    </div>
                    {error && (
                      <p className="login-error text-red mb-1">{error}</p>
                    )}
                    <div className="button-row">
                      <button
                        type="submit"
                        className="btn btn--primary group mr-1 mb-1"
                      >
                        <div className="btn__label">Bekreft</div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={onGoBack}
                        className="btn btn--secondary group mb-1"
                      >
                        <div className="btn__label">
                          <span>Avbryt</span>
                        </div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BateCreateUser
