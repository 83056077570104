import React, { useState, useContext, Fragment, useEffect } from 'react'
import axios from 'axios'
import { Button, Header, Text, Link, Alert, Input } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import {
  CreateLinkWrapper,
  ProvidersWrapper,
  VippsButton,
  VippsImage,
  Wrapper,
} from './styles'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'App'
import { getLoginErrorMessage } from 'shared/services/errorMessages'
import { removeSpaces } from 'shared/utils/string'
import * as Yup from 'yup'
import { getUrlWithBblAndReturnUrl } from 'shared/utils/returnUrl'
import { Formik, Form } from 'formik'
import PasswordField from 'shared/components/PasswordField'
import { UserValidation, PasswordValidation } from 'shared/utils/validations'
import { getParamBothLowerUppercase } from 'shared/utils/params'
import { getVippsUrl } from 'shared/utils/url'
import { useAnalyticsContext } from '@bbld/bbl-analytics'
import ErrorModal from 'shared/components/ErrorModal'
import BateLogin from 'Modules/Bate/Login'
import useBrandingTheme from 'shared/utils/useBrandingTheme'

interface IProps {}

export const SigninSchema = Yup.object().shape({
  username: UserValidation,
  password: PasswordValidation,
})

const Login: React.FC<IProps> = (props) => {
  const analyticsContext = useAnalyticsContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>()
  const history = useHistory()
  const ignoreRedirect = getParamBothLowerUppercase('ignoreRedirect')
  const { brandingTheme } = useBrandingTheme()

  const appContext = useContext(AppContext)
  const ReturnUrl = getParamBothLowerUppercase('ReturnUrl')

  const vippsUrl = getVippsUrl(appContext.returnUrl)

  const [externalError, setExternalError] = useState<string | null>(
    getParamBothLowerUppercase('error')
  )

  useEffect(() => {
    if (appContext.loginWithCode && !ignoreRedirect) {
      history.replace('loginWithCode' + history.location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = (values: { username: string; password: string }) => {
    setLoading(true)

    axios
      .post('/account/login', {
        username: removeSpaces(values.username),
        password: values.password,
        ReturnUrl,
      })
      .then((res: any) => {
        analyticsContext.sendEvent(
          'Login with password - Logged in',
          'Logged in'
        )
        window.location.assign(
          (window.config.bblidBaseUrl + res.data.redirectUrl) as any
        )
      })
      .catch((err) => {
        setError(getLoginErrorMessage(err.response.data.code))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const goToCode = () => {
    history.push({
      pathname: '/loginWithCode',
      search:
        getUrlWithBblAndReturnUrl(appContext.bbl, appContext.returnUrl) +
        '&ignoreRedirect=true',
    })
  }

  const goToForgottenPassord = () => {
    history.push({
      pathname: '/glemt-passord',
      search: getUrlWithBblAndReturnUrl(appContext.bbl, appContext.returnUrl),
    })
  }

  const goToCreateUser = () => {
    history.push({
      pathname: '/opprett',
      search: getUrlWithBblAndReturnUrl(appContext.bbl, appContext.returnUrl),
    })
  }

  const goBack = () => {
    if (appContext.enableMFA) {
      goToCode()
      return
    }
    if (appContext.returnUrl) {
      const urlParams = new URLSearchParams(appContext.returnUrl)
      window.location.href = urlParams.get('redirect_uri') + '?cancel=true'
    }
  }

  if (brandingTheme === 'bate')
    return (
      <BateLogin
        goToCreateUser={goToCreateUser}
        vippsUrl={vippsUrl}
        goToForgotPassword={goToForgottenPassord}
        error={error}
        loading={loading}
        clearError={() => setError(undefined)}
        onSubmit={submit}
      />
    )

  return (
    <Layout>
      <Button back onClick={goBack}
	  trackingName="Login"
	  trackingEvent="Login click: Tilbake">
        Tilbake
      </Button>
      <Wrapper>
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={SigninSchema}
          onSubmit={(values) => {
            submit(values)
          }}
        >
          {({ errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <Header level={2}>Logg inn</Header>
              <Input
                name="username"
                type="text"
                label="E-post eller mobilnummer"
                focus
                validation={true}
                invalidMessage={
                  errors.username && touched.username ? errors.username : null
                }
                onChange={(e: any) => {
                  setFieldValue('username', e.target.value)
                  setError('')
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setFieldTouched('username')
                  }, 300)
                }}
              />
              <PasswordField
                name="password"
                label="Passord"
                validation={true}
                invalidMessage={
                  errors.password && touched.password ? errors.password : null
                }
                onChange={(e: any) => {
                  setFieldValue('password', e.target.value)
                  setError('')
                }}
                onBlur={() => {
                  setFieldTouched('password')
                }}
              />

              {error && (
                <Alert danger onClose={() => setError('')}>
                  {error}
                </Alert>
              )}

              <Link
                className="link"
                textAlign="right"
                onClick={() => goToForgottenPassord()}
              >
                Glemt passord?
              </Link>

              <Button type="submit" loading={loading} highlight
			  trackingName="Login"
			  trackingEvent="Login click: Logg inn">
                Logg inn
              </Button>

              {!appContext.enableMFA && appContext.enableVipps && (
                <Fragment>
                  <ProvidersWrapper>
                    <Text align="center">Benytt annen innloggingsmetode</Text>
                  </ProvidersWrapper>
                  <Fragment>
                    <VippsButton href={vippsUrl}>
                      <VippsImage
                        src={
                          window.config.themeUrl +
                          'shared/images/Vipps-Login-Btn.svg'
                        }
                      />
                    </VippsButton>
                  </Fragment>
                </Fragment>
              )}

              {!appContext.enableMFA && (
                <CreateLinkWrapper>
                  <Text>Ikke bruker enda?</Text>
                  <Link onClick={goToCreateUser}>Opprett ny bruker</Link>
                </CreateLinkWrapper>
              )}
            </Form>
          )}
        </Formik>
      </Wrapper>
      {externalError && (
        <ErrorModal
          error={externalError}
          onClose={() => setExternalError(null)}
        />
      )}
    </Layout>
  )
}

export default Login
