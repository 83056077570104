import React from 'react'
import { Text, Header } from '@bbl-digital/snorre'
import { Wrapper } from './styles'
import { IBbl } from 'shared/models/bbl'

interface IProps {
  bbl: IBbl | null
}

const CreateInformation: React.FC<IProps> = ({ bbl }) => {
  return (
    <Wrapper>
      <Header level={1}>
        Velkommen!
      </Header>
      <Text size="20px" paragraph>
        For å kunne opprette en bruker, må du være registrert eller medlem hos{' '}
        {bbl?.shortName || bbl?.name}
        <br />
        Ta kontakt med oss dersom du ikke er medlem eller får registrert
        brukeren din.
      </Text>
      <Text size="20px" paragraph>
        Er du hverken registrert eller medlem? Ta kontakt med oss.
      </Text>
      <Text size="20px" paragraph>
        Vennligst bruk samme e-postadresse eller mobilnummer du tidligere har
        oppgitt.
      </Text>
    </Wrapper>
  )
}

export default CreateInformation
