import axios from 'axios'

export const loginPasswordless = (
  username: string,
  code: string,
  token: string,
  ReturnUrl: string | null,
  dateOfBirth?: string
) => {
  return axios.post('/api/login/passwordless', {
    username,
    code,
    recaptchaToken: token,
    ReturnUrl,
    dateOfBirth,
  })
}
