import React, { Fragment } from 'react'
import { Header, Text } from '@bbl-digital/snorre'
import { IBbl } from 'shared/models/bbl'
import { IPerson } from 'shared/models/person'

interface IProps {
  bbl: IBbl | null
  person: IPerson | null
}

const NoAccessInformation: React.FC<IProps> = ({ person, bbl }) => {
  return (
    <Fragment>
      <Header level={2}>Hei {person?.firstName}</Header>
      <Text paragraph>
        Vi klarte dessverre ikke å automatisk koble din bruker i {bbl?.name}{' '}
        sine systemer.
      </Text>
      <Text paragraph>
        Om du finnes i {bbl?.name} sine systemer kan vårt kundesenter gjerne
        hjelpe deg videre med opprettingen.
      </Text>
      <Text paragraph>
        Trykk på knappen under for å sende en forespørsel med dine
        brukerdetaljer til vårt kundesenter hos {bbl?.name}.
      </Text>
    </Fragment>
  )
}

export default NoAccessInformation
