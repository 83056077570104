import styled from '@emotion/styled'
import bp from '@bbl-digital/snorre/lib/globals/styles/breakpoints'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 850px;
  margin: 0 auto;
  margin-top: 1em;

  & > button {
    width: max-content;
    margin-bottom: 2em;
  }

  h3,
  h4 {
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: ${bp.xlarge}) {
    padding: 0 1em;
  }

  @media screen and (max-width: ${bp.small}) {
    margin-top: 0;

    button {
      margin-top: 0;
    }
  }
`

export const Section = styled.div`
  margin-bottom: 1.5em;
`

export const Break = styled.br``
