import React from 'react'

function BateLoader() {
  return (
    <div className="loader-container">
      <div
        className="loader"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bate/gfx/loader.png)` }}
      />
    </div>
  )
}

export default BateLoader
