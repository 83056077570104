import React from 'react'
import { Alert, Button, Input } from '@bbl-digital/snorre'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { PhoneValidation } from 'shared/utils/validations'

interface IProps {
  phone: string
  loading: boolean
  error: string | null
  onSubmit: (values: { phone: string }) => void
  onCancel: () => void
  onRemoveError: () => void
}

const EditPhoneSchema = Yup.object().shape({
  phone: PhoneValidation,
})

const PhoneForm: React.FC<IProps> = ({
  error,
  phone,
  loading,
  onSubmit,
  onCancel,
  onRemoveError,
}) => {
  return (
    <Formik
      initialValues={{
        phone,
      }}
      validationSchema={EditPhoneSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Input
            type="text"
            label="Mobilnummer"
            value={phone}
            validation={true}
            invalidMessage={errors.phone && touched.phone ? errors.phone : null}
            onChange={(e: any) => {
              onRemoveError()
              setFieldValue('phone', e.target.value.replace(/\s/g, ''))
            }}
            onBlur={() => {
              setTimeout(() => setFieldTouched('phone'))
            }}
          />
          {error && <Alert danger>{error}</Alert>}
          <Button type="submit" loading={loading}
		  trackingName="Profile"
		  trackingEvent="Edit phone details click: Lagre">
            Lagre
          </Button>
          <Button outline onClick={onCancel}
		  trackingName="Profile"
		  trackingEvent="Edit phone details click: Avbryt">
            Avbryt
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default PhoneForm
