import React from 'react'
import {
  Input,
  Button,
  Text,
  Link,
  Alert,
  Card,
  Header,
} from '@bbl-digital/snorre'
import * as Yup from 'yup'
import { UserValidation } from '../../../shared/utils/validations'
import { Wrapper } from './styles'
import { Form, Formik } from 'formik'
import { CardContent } from 'shared/components/CardContent'

interface IProps {
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onGoBack: () => void
  onSubmit: (values: { username: string }) => void
}

export const ForgotPasswordSchema = Yup.object().shape({
  username: UserValidation,
})

const RequestNewPassword: React.FC<IProps> = ({
  loading,
  error,
  onRemoveError,
  onGoBack,
  onSubmit,
}) => {
  return (
    <Wrapper>
      <Card shadow>
        <CardContent>
          <Header level={2}>Glemt passord</Header>

          <Text paragraph>
            Skriv inn mobilnummer eller e-post. Du vil motta en engangskode for
            endring av passord.
          </Text>

          <Formik
            initialValues={{
              username: '',
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values) => {
              onSubmit(values)
            }}
          >
            {({ errors, touched, dirty, setFieldValue, setFieldTouched }) => (
              <Form>
                <Input
                  type="text"
                  label="E-post eller mobilnummer"
                  focus
                  validation={true}
                  invalidMessage={
                    errors.username && touched.username ? errors.username : null
                  }
                  onChange={(e: any) => {
                    setFieldValue('username', e.target.value)
                    onRemoveError()
                    setTimeout(() => setFieldTouched('username'))
                  }}
                />

                {error && (
                  <Alert danger onClose={() => onRemoveError()}>
                    {error}
                  </Alert>
                )}

                <Button loading={loading} highlight type="submit"
				trackingName="Forgot password"
				trackingEvent="Request new password click: Send">
                  Send
                </Button>
              </Form>
            )}
          </Formik>
          <Link onClick={() => onGoBack()}>Avbryt</Link>
        </CardContent>
      </Card>
    </Wrapper>
  )
}

export default RequestNewPassword
