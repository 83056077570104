import React from 'react'
import { Input, Button, Text, Alert } from '@bbl-digital/snorre'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ConfirmationCode, PasswordValidation } from 'shared/utils/validations'
import { FormContent } from './styles'
import PasswordField from 'shared/components/PasswordField'

interface IProps {
  confirmtype: 'mobile' | 'email'
  username: string
  loading: boolean
  error: string | null
  onCancel: () => void
  onRemoveError: () => void
  onSubmit: (
    confirmtype: 'mobile' | 'email',
    values: {
      username: string
      code: string
      password: string
    }
  ) => void
}

const ConfirmSchema = Yup.object().shape({
  code: ConfirmationCode,
  password: PasswordValidation,
})

const ConfirmForm: React.FC<IProps> = ({
  confirmtype,
  username,
  loading,
  error,
  onCancel,
  onRemoveError,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        username: username,
        code: '',
        password: '',
      }}
      validationSchema={ConfirmSchema}
      onSubmit={(values) => {
        onSubmit(confirmtype, values)
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <FormContent>
            <Text paragraph>
              Vi har sendt deg en engangskode til {username}. Vennligst benytt
              denne engangskoden for å bekrefte din bruker.
            </Text>

            <Input
              type="text"
              label="Engangskode"
              focus
              validation={true}
              invalidMessage={errors.code && touched.code ? errors.code : null}
              onChange={(e: any) => {
                setFieldValue('code', e.target.value)
                onRemoveError()
                setTimeout(() => setFieldTouched('code'))
              }}
            />

            <PasswordField
              name="password"
              label="Bekreft endringen med ditt passord"
              validation={true}
              invalidMessage={
                errors.password && touched.password ? errors.password : null
              }
              onChange={(e: any) => {
                setFieldValue('password', e.target.value)
                onRemoveError()
                setTimeout(() => setFieldTouched('password'))
              }}
            />

            {error && (
              <Alert danger onClose={() => onRemoveError()}>
                {error}
              </Alert>
            )}

            <Button type="submit" loading={loading}
			trackingName="Profile"
			trackingEvent="Details click: Bekreft">
              Bekreft
            </Button>

            <Button outline onClick={onCancel}
			trackingName="Profile"
			trackingEvent="Details click: Avbryt">
              Avbryt
            </Button>
          </FormContent>
        </Form>
      )}
    </Formik>
  )
}

export default ConfirmForm
