import React from 'react'
import { Wrapper } from './styles'
import { IBbl } from 'shared/models/bbl'
import CreateInformation from './CreateInformation'
import CreateForm from './CreateForm'

interface IProps {
  bbl: IBbl | null
  showDatepicker: boolean
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onGoBack: () => void
  onSubmit: (values: {
    username: string
    password: string
    birthDate: string | null
  }) => void
}

const Create: React.FC<IProps> = ({
  bbl,
  showDatepicker,
  loading,
  error,
  onSubmit,
  onRemoveError,
  onGoBack,
}) => {
  return (
    <Wrapper>
      <CreateInformation bbl={bbl} />
      <CreateForm
        showDatepicker={showDatepicker}
        loading={loading}
        error={error}
        onRemoveError={onRemoveError}
        onSubmit={onSubmit}
        onGoBack={onGoBack}
      />
    </Wrapper>
  )
}

export default Create
