export enum RegistrationEnum {
  UnknownAuthenticationMethod = 10001,
  UserExistWithConfirmedEmailOrPhone = 10002,
  MoreThanOneResult = 10003,
  ConfirmationCodeSent = 10004,
  FailedCreatingUser = 10005,
  NoUserFound = 10006,
  WrongConfirmationCode = 10007,
  NotValidPasswordFormat = 10008,
  PhoneNumberIsUsed = 10009,
  EmailIsUsed = 10010,
}

export enum RecoverPasswordEnum {
  UserDoesNotExist = 10001,
  InvalidPasswordFormat = 10002,
  WrongOneTimeCode = 10003,
}

export enum LoginEnum {
  UserDoesNotExist = 10001,
  InvalidReturnUrl = 10002,
  WrongUsernameOrPassword = 10003,
}

export enum UpdateProfileEnum {
  FailedValidation = 10001,
  PhoneAlreadyInUse = 10002,
  EmailAlreadyInUse = 10004,
  Ok = 10003,
}

export enum DeleteProfileEnum {
  WrongPassword = 10001,
  FailedDeletingUser = 10002,
}
