import React from 'react'
import { IllustrationError, Header } from '@bbl-digital/snorre'
import { ErrorWrapper } from './styles'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error)
    console.log(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorWrapper>
          <IllustrationError />
          <Header level={2}>Ops! Her har det skjedd en feil...</Header>
        </ErrorWrapper>
      )
    }

    return (this.props as any).children
  }
}

export default ErrorBoundary
