import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'

const Logout: React.FC = () => {
  const authContext = useAuthContext()

  useEffect(() => {
    authContext.signoutRedirectCallback()
  })

  // TODO change with correct loader
  return <p>Loading</p>
}

export default Logout
