import { Form, Formik } from 'formik'
import { NewPasswordSchema } from 'Modules/ForgotPassword/CreateNewPassword'
import { ForgotPasswordSchema } from 'Modules/ForgotPassword/RequestNewPassword'
import React from 'react'
import BateLoader from 'shared/components/BateLoader'

interface IProps {
  user: string | undefined
  loading: boolean
  error: string
  onGoBack: () => void
  onRemoveError: () => void
  onRequestSubmit: ({ username }: { username: string }) => void
  onCodeSubmit: ({
    username,
    code,
    password,
  }: {
    username: string
    code: string
    password: string
  }) => void
}

function BateForgotPassword({
  user,
  loading,
  error,
  onGoBack,
  onRemoveError,
  onRequestSubmit,
  onCodeSubmit,
}: IProps) {
  return (
    <section className="main-section w-full">
      {loading && <BateLoader />}
      <div className="container container--small">
        <div className="box">
          <div className="box__icon">
            <img
              src={process.env.PUBLIC_URL + '/bate/gfx/logg_inn.png'}
              alt=""
              className="box__icon__image"
            />
          </div>
          <h1 className="font-clanot-ultra text-red">Glemt passord</h1>
          {!user && (
            <>
              <p className="content">
                Skriv inn mobilnummer eller e-post. Du vil motta en engangskode
                for endring av passord.
              </p>

              <Formik
                initialValues={{
                  username: '',
                }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={(values) => {
                  onRequestSubmit(values)
                }}
              >
                {({
                  errors,
                  touched,
                  dirty,
                  submitForm,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <div className="form mb-2">
                      <label className="text-red font-clanot-black">
                        E-post eller mobilnummer
                      </label>
                      <input
                        name="username"
                        type="text"
                        autoFocus
                        className="input-text"
                        placeholder="Skriv inn her"
                        onChange={(e: any) => {
                          setFieldValue('username', e.target.value)
                          onRemoveError()
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setFieldTouched('username')
                          }, 300)
                        }}
                      />
                    </div>
                    {error && (
                      <p className="login-error text-red mb-1">{error}</p>
                    )}
                    <div className="button-row">
                      <button
                        type="submit"
                        className="btn btn--primary group mr-1 mb-1"
                      >
                        <div className="btn__label">Send</div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={onGoBack}
                        className="btn btn--secondary group mb-1"
                      >
                        <div className="btn__label">
                          <span>Avbryt</span>
                        </div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {user && (
            <>
              <p className="content">
                Vi har sendt deg en engangskode til {user}. Vennligst benytt
                denne engangskoden for å bekrefte din bruker.
              </p>

              <Formik
                initialValues={{
                  username: user || '',
                  code: '',
                  password: '',
                }}
                validationSchema={NewPasswordSchema}
                onSubmit={(values) => {
                  onCodeSubmit(values)
                }}
              >
                {({
                  errors,
                  touched,
                  submitForm,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <div className="form mb-2">
                      <label className="text-red font-clanot-black">
                        Engangskode
                      </label>
                      <input
                        name="code"
                        type="text"
                        className="input-text"
                        autoFocus
                        placeholder="Skriv inn her"
                        autoComplete="off"
                        onChange={(e: any) => {
                          setFieldValue('code', e.target.value)
                          onRemoveError()
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setFieldTouched('code')
                          }, 300)
                        }}
                      />
                      <label className="text-red font-clanot-black">
                        Nytt passord
                      </label>
                      <input
                        name="password"
                        type="password"
                        autoComplete="off"
                        className="input-text"
                        placeholder="Skriv inn her"
                        onChange={(e: any) => {
                          setFieldValue('password', e.target.value)
                          onRemoveError()
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setFieldTouched('password')
                          }, 300)
                        }}
                      />
                    </div>
                    {error && (
                      <p className="login-error text-red mb-1">{error}</p>
                    )}
                    <div className="button-row">
                      <button
                        type="submit"
                        className="btn btn--primary group mr-1 mb-1"
                      >
                        <div className="btn__label">Send</div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={onGoBack}
                        className="btn btn--secondary group mb-1"
                      >
                        <div className="btn__label">
                          <span>Avbryt</span>
                        </div>
                        <svg
                          className="btn__arrow"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default BateForgotPassword
