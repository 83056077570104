import axios from 'axios'

// TODO: Add parameters
export const getTheme = async (brandingCode: string) => {
  return axios.get(
    window.config.themeUrl + brandingCode.toLowerCase() + '/styles.json',
    { withCredentials: false }
  )
}

export const setFavicon = (code: string) => {
  const favicon: any = document.querySelector("link[rel*='icon']")
  if (!favicon) return
  favicon.href = `${
    window.config.themeUrl
  }${code.toLocaleLowerCase()}/favicon.ico`
}
