import { Button, IconClose } from '@bbl-digital/snorre'
import styled from '@emotion/styled'
import React from 'react'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
  margin-bottom: 2rem;
`

interface IProps {
  title: string
  onClose: () => void
  children: React.ReactNode
}

const BateModal = ({ title, onClose, children }: IProps) => {
  return (
    <ModalWrapper>
      <div className="container container--xs">
        <div className="box">
          <ModalHeader>
            <p className="text-lg text-red m-0">{title}</p>
            <Button nostyle onClick={onClose}>
              <IconClose color="#E21219" />
            </Button>
          </ModalHeader>
          {children}
        </div>
      </div>
    </ModalWrapper>
  )
}

export default BateModal
