import React from 'react'
import { Card, Header, Button, Text, Alert, Link } from '@bbl-digital/snorre'
import { CardContent } from 'shared/components/CardContent'
import { IUserRegistration } from 'shared/models/user'
import { getFormattedDate } from 'shared/utils/dates'
import { Wrapper, UserInformation } from './styles'

interface IProps {
  user: IUserRegistration
  loading: boolean
  error: string | null
  passwordLess?: boolean
  onDone: () => void
  onCancel: () => void
  onRemoveError: () => void
}

const VerifyUser: React.FC<IProps> = ({
  user,
  loading,
  error,
  passwordLess,
  onDone,
  onCancel,
  onRemoveError,
}) => {
  return (
    <Wrapper>
      <Card shadow>
        <CardContent>
          <Header level={2}>Dine opplysninger</Header>

          <Text>
            Vi har følgende informasjon registrert om deg i vårt kunderegister.
            Vennligst bekreft at denne informasjonen er riktig.
          </Text>

          <UserInformation>
            <Text semibold>Navn</Text>
            <Text>{user.firstName + ' ' + user.lastName}</Text>

            <Text semibold>Fødselsdato</Text>
            <Text>
              {user.dateOfBirth
                ? getFormattedDate(user.dateOfBirth, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                : 'Ikke angitt'}
            </Text>

            <Text semibold>Mobilnummer</Text>
            <Text>{user.phoneNumber ? user.phoneNumber : 'Ikke angitt'}</Text>

            <Text semibold>E-post</Text>
            <Text>{user.email ? user.email : 'Ikke angitt'}</Text>
          </UserInformation>

          {error && (
            <Alert danger onClose={() => onRemoveError()}>
              {error}
            </Alert>
          )}

          {!passwordLess && (
            <Text subtle>
              Ved å bekrefte bruker blir du sendt til logg inn.
            </Text>
          )}
          
          <Button loading={loading} onClick={onDone}
		  trackingName="Create user"
		  trackingEvent="Verify user click: Bekreft">
            Bekreft
          </Button>
          <Link onClick={onCancel}>Avbryt registrering</Link>

          <Text subtle>
            Dersom noe av informasjonen er feil, vennligst ta kontakt med oss.
          </Text>
        </CardContent>
      </Card>
    </Wrapper>
  )
}

export default VerifyUser
