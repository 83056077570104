import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

let reactPlugin: ReactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights | null = null

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (instrumentationKey: string, browserHistory: History) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service')
    }
    if (!instrumentationKey) {
      throw new Error(
        'Instrumentation key not provided in ./src/telemetry-provider.jsx'
      )
    }

    reactPlugin = new ReactPlugin()

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    })

    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      if (!envelope || !envelope.tags) return

      const xbid = document.cookie
        .split(';')
        .find((cstring: string) => cstring.trim().startsWith('xbid'))
        ?.split('=')[1]

      envelope.tags['ai.cloud.role'] = window.config.aiCloudRole
      if (!envelope.data) {
        envelope.data = {
          XTrackingId: xbid,
          locationUrl: window.location.pathname + window.location.search,
        }
      } else {
        envelope.data.XTrackingId = xbid
        envelope.data.locationUrl =
          window.location.pathname + window.location.search
      }
    })
  }

  return { reactPlugin, appInsights, initialize }
}

export const ai = createTelemetryService()
export const getAppInsights = () => appInsights
