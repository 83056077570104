import { useEffect, useState } from 'react'
import { getParamBothLowerUppercase } from './params'

const useBrandingTheme = () => {
  const [brandingTheme, setBrandingTheme] = useState('default')

  const getTheme = () => {
    const bblCode = getParamBothLowerUppercase('bbl')?.toLocaleLowerCase()
    // return bblCode === 'bate' || bblCode === 'bbldtest04' ? 'bate' : 'default' // For testing purposes
    return bblCode === 'bate' ? 'bate' : 'default'
  }

  useEffect(() => {
    setBrandingTheme(getTheme())
  }, [])

  return {
    brandingTheme,
  }
}

export default useBrandingTheme
