import { Input, Alert } from '@bbl-digital/snorre'
import { useAuthContext } from 'Auth/AuthContext'
import axios from 'axios'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Button } from 'react-day-picker'
import BateModal from 'shared/components/BateModal'
import { API_DELETE_USER } from 'shared/constants/apiEndpoints'
import { RegistrationEnum } from 'shared/models/enums'
import { IPerson } from 'shared/models/person'
import { getDeleteProfileErrorMessage } from 'shared/services/errorMessages'
import { ConfirmationCode } from 'shared/utils/validations'

const ConfirmUserSchema = Yup.object().shape({
  code: ConfirmationCode,
})

interface IProps {
  person: IPerson
}

const DeleteUser = ({ person }: IProps) => {
  const authContext = useAuthContext()
  const [username, setUsername] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const close = () => setUsername(undefined)

  const requestCode = async (user: string) => {
    try {
      await axios.delete(API_DELETE_USER, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
        data: { EmailOrPhone: user, Code: null },
      })

      setUsername(user)
    } catch (err: any) {
      if (err.response.data === RegistrationEnum.ConfirmationCodeSent) {
        setUsername(user)
      } else {
        setError(getDeleteProfileErrorMessage(err.response.data))
      }
    }
  }

  const submitCode = async ({ code }: { code: string }) => {
    try {
      await axios.delete(API_DELETE_USER, {
        headers: { Authorization: 'Bearer ' + authContext.getToken() },
        data: { EmailOrPhone: username, Code: code },
      })
      authContext.logoutGateway()
    } catch (err: any) {
      setError(getDeleteProfileErrorMessage(err.response.data))
    }
  }

  return (
    <>
      <div className="delete-user">
        {!person.phoneNumberConfirmed && person.emailConfirmed && (
          <a href="#" onClick={() => requestCode(person.email)}>
            Slett din bruker
          </a>
        )}
        {person.phoneNumberConfirmed && (
          <a href="#" onClick={() => requestCode(person.phoneNumber)}>
            Slett din bruker
          </a>
        )}
      </div>
      {username ? (
        <BateModal title="Slett bruker" onClose={close}>
          <Formik
            initialValues={{
              code: '',
            }}
            validationSchema={ConfirmUserSchema}
            onSubmit={(values) => {
              submitCode(values)
            }}
          >
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <p style={{ color: '#666' }}>
                  Vi har sendt deg en engangskode til {username}. Vennligst
                  benytt denne engangskoden for å bekrefte sletting av bruker.
                </p>

                <div className="form">
                  <label className="text-red font-clanot-black">
                    Engangskode
                  </label>
                  <input
                    type="text"
                    name="code"
                    className="input-text"
                    placeholder="Skriv inn her"
                    onChange={(e: any) => {
                      setFieldValue('code', e.target.value)
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setFieldTouched('code')
                      }, 300)
                    }}
                  />
                </div>
                {error ? <p>{error}</p> : null}
                <button
                  type="submit"
                  className="btn btn--primary btn--icon group"
                  style={{
                    marginTop: '12px',
                    height: '70px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                  }}
                >
                  <div className="btn__label">
                    <span>Bekreft</span>
                  </div>
                  <svg
                    className="btn__arrow"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                  </svg>
                </button>
              </Form>
            )}
          </Formik>
        </BateModal>
      ) : null}
    </>
  )
}

export default DeleteUser
