import React from 'react'
import {
  Input,
  Button,
  Text,
  Link,
  Alert,
  Card,
  Header,
} from '@bbl-digital/snorre'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ConfirmationCode, PasswordValidation } from 'shared/utils/validations'
import { CardContent } from 'shared/components/CardContent'
import { Wrapper } from './styles'

interface IProps {
  username: string
  loading: boolean
  error: string | null
  onRemoveError: () => void
  onGoBack: () => void
  onSubmit: (values: {
    username: string
    code: string
    password: string
  }) => void
}

export const NewPasswordSchema = Yup.object().shape({
  code: ConfirmationCode,
  password: PasswordValidation,
})

const CreateNewPassword: React.FC<IProps> = ({
  username,
  loading,
  error,
  onSubmit,
  onRemoveError,
  onGoBack,
}) => {
  return (
    <Wrapper>
      <Card shadow>
        <CardContent>
          <Header level={2}>Bekreft med engangskode</Header>

          <Formik
            initialValues={{
              username: username,
              code: '',
              password: '',
            }}
            validationSchema={NewPasswordSchema}
            onSubmit={(values) => {
              onSubmit(values)
            }}
          >
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <Text paragraph>
                  Vi har sendt deg en engangskode til{' '}
                  <Text semibold>{username}</Text>. Vennligst benytt denne
                  engangskoden for å bekrefte din bruker.
                </Text>

                <Input
                  type="text"
                  label="Engangskode"
                  focus
                  validation={true}
                  invalidMessage={
                    errors.code && touched.code ? errors.code : null
                  }
                  onChange={(e: any) => {
                    setFieldValue('code', e.target.value)
                    onRemoveError()
                    setTimeout(() => setFieldTouched('code'))
                  }}
                />

                <Input
                  type="password"
                  label="Nytt passord"
                  validation={true}
                  onChange={(e: any) => {
                    setFieldValue('password', e.target.value)
                    onRemoveError()
                    setTimeout(() => setFieldTouched('password'))
                  }}
                ></Input>

                {error && (
                  <Alert danger onClose={() => onRemoveError()}>
                    {error}
                  </Alert>
                )}

                <Button highlight type="submit" loading={loading}
				trackingName="Forgot password"
				trackingEvent="Create new password click: Fortsett">
                  Fortsett
                </Button>
                <Link onClick={onGoBack}>Avbryt</Link>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Wrapper>
  )
}

export default CreateNewPassword
