import React, { useState, useContext, Fragment, useEffect } from 'react'
import axios from 'axios'
import { Button, Link, Alert } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { Wrapper } from './styles'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'App'
import { removeSpaces } from 'shared/utils/string'
import VerifyUser from 'Modules/CreateUser/VerifyUser'
import { getUrlWithBblAndReturnUrl } from 'shared/utils/returnUrl'
import { IUserRegistration } from 'shared/models/user'
import UsernameForm from './UsernameForm'
import CodeForm from './CodeForm'
import DateOfBirthForm from './DateOfBirthForm'
import { loginPasswordless } from 'shared/api/login'
import { getFormatedPhone } from 'shared/utils/phone'
import {
  ERROR_CODE_TO_MANY_TRIES,
  ERROR_MULTIPLE_PERSON_DATEOFBIRTH,
  ERROR_RECAPTCHA,
} from 'shared/constants/errorMessages'
import { fetchBbl } from 'shared/api/bbl'
import { IBbl } from 'shared/models/bbl'
import { useAnalyticsContext } from '@bbld/bbl-analytics'
import { getParamBothLowerUppercase } from 'shared/utils/params'
import ErrorModal from 'shared/components/ErrorModal'

enum StepEnum {
  Username = 1,
  Code = 2,
  Verification = 3,
  DateOfBirth = 4,
}

const SITE_KEY = `6Le-gjoeAAAAAGFbBgZv9liN5lSaP7_TjPn01smx`

const LoginWithCode: React.FC = () => {
  const analyticsContext = useAnalyticsContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [step, setStep] = useState(StepEnum.Username)
  const [code, setCode] = useState('')
  const [username, setUsername] = useState<string>('')
  const [token, setToken] = useState('')
  const [phoneNumber, setPhone] = useState('0')
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>()
  const [user, setUser] = useState<IUserRegistration | undefined>()
  const [bbl, setBbl] = useState<IBbl | null>()
  const [externalError, setExternalError] = useState<string | null>(
    getParamBothLowerUppercase('error')
  )
  const history = useHistory()
  const appContext = useContext(AppContext)

  const search = window.location.search
  const params = new URLSearchParams(search)
  const ReturnUrl = params.get('ReturnUrl') || params.get('returnurl')

  useEffect(() => {
    fetchBbl()
      .then((res: IBbl) => {
        setBbl(res)
      })
      .catch(() => {
        setBbl(null)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: any) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/enterprise.js?render=${SITE_KEY}`,
      () => {
        getCaptcha()
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCaptcha = async () => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(SITE_KEY, { action: 'passwordLessLogin' })
        .then((token: string) => {
          setToken(token)
        })
    })

    setTimeout(() => {
      getCaptcha()
    }, 90 * 1000) // Token expire time is 2minutes, refresh token every 90 sec
  }

  const submitUsername = (values: { username: string }) => {
    setLoading(true)

    loginPasswordless(removeSpaces(values.username), '', token, ReturnUrl)
      .then((res: any) => {
        getCaptcha()
        analyticsContext.sendEvent(
          'Login with code - Requested code',
          'Requested code'
        )
        if (res.data.authenticationMethod === 2) {
          setUsername(getFormatedPhone(values.username))
        } else {
          setUsername(values.username)
        }
        setStep(StepEnum.Code)
      })
      .catch((err) => {
        getCaptcha()
        if (err.response.data.code === 1000) {
          setError(ERROR_RECAPTCHA)
        } else {
          setError(err.response.data.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submitCode = (values: { code: string; dateOfBirth?: string }) => {
    setLoading(true)
    loginPasswordless(
      removeSpaces(username || ''),
      values.code,
      token,
      ReturnUrl,
      values.dateOfBirth
    )
      .then((res: any) => {
        getCaptcha()
        analyticsContext.sendEvent('Login with code - Logged in', 'Logged in')
        window.location.assign(
          (window.config.bblidBaseUrl + res.data.redirectUrl) as any
        )
      })
      .catch((err) => {
        getCaptcha()
        if (err.response.data.code === 104) {
          //User does not exist
          setStep(StepEnum.Verification)
          setCode(values.code)
          if (values.dateOfBirth) {
            setDateOfBirth(values.dateOfBirth)
          }
          setPhone(username || '')
          setUser(err.response.data.responseData)
        } else if (err.response.data.code === 123) {
          setError(
            `Vi kan ikke finne noen personer med:<br /><strong>${username}</strong><br /><br />For å opprette bruker må en være registrert hos ${
              bbl?.shortName || bbl?.name
            }.<br /><br />Ta gjerne kontakt for videre bistand.`
          )
        } else if (err.response.data.code === 401) {
          setCode(values.code)
          setStep(StepEnum.Username)
          setError(ERROR_CODE_TO_MANY_TRIES)
        } else if (err.response.data.code === 10003) {
          if (step === StepEnum.DateOfBirth) {
            setError(ERROR_MULTIPLE_PERSON_DATEOFBIRTH)
            return
          }
          setStep(StepEnum.DateOfBirth)
          setCode(values.code)
          setPhone(username || '')
        } else if (err.response.data.code === 1000) {
          setError(ERROR_RECAPTCHA)
        } else {
          if (err.response.data.message) setError(err.response.data.message)
          else setError('En feil skjedde.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const goToLoginUser = () => {
    history.push({
      pathname: '/login',
      search:
        getUrlWithBblAndReturnUrl(appContext.bbl, appContext.returnUrl) +
        '&ignoreRedirect=true',
    })
  }

  const goBack = () => {
    if (step === StepEnum.Code) {
      setStep(StepEnum.Username)
      setError(null)
      return
    }
    if (appContext.returnUrl) {
      const urlParams = new URLSearchParams(appContext.returnUrl)
      window.location.href = urlParams.get('redirect_uri') + '?cancel=true'
    }
  }

  const submitVerify = () => {
    setLoading(true)
    axios
      .post('/api/login/passwordless', {
        username: removeSpaces(phoneNumber),
        code: code,
        dateOfBirth: dateOfBirth,
        ReturnUrl,
        recaptchaToken: token,
        User: user,
      })
      .then((res: any) => {
        getCaptcha()
        if (step === StepEnum.Username) setStep(StepEnum.Code)
        if (step === StepEnum.Verification)
          window.location.assign(
            (window.config.bblidBaseUrl + res.data.redirectUrl) as any
          )
      })
      .catch((err) => {
        getCaptcha()
        setError(err.response.data.message)
        setStep(StepEnum.Username)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCancel = () => {
    setStep(StepEnum.Username)
  }

  const verify = user ? (
    <VerifyUser
      user={user}
      loading={loading}
      error={error}
      passwordLess={true}
      onDone={submitVerify}
      onCancel={onCancel}
      onRemoveError={() => setError(null)}
    ></VerifyUser>
  ) : (
    <Fragment>
      <Alert danger>En feil skjedde</Alert>
      <Link onClick={goBack}>Tilbake</Link>
    </Fragment>
  )

  const loginFlow = (
    <>
      {step === StepEnum.Username && (
        <UsernameForm
          loading={loading}
          vipps={appContext.enableVipps}
          vippsReturnUrl={appContext.returnUrl}
          error={error}
          onSubmit={submitUsername}
          onBack={goBack}
          onUsePasswordLogin={goToLoginUser}
          onClearError={() => setError(null)}
        />
      )}
      {step === StepEnum.Code && (
        <CodeForm
          username={username}
          loading={loading}
          error={error}
          onSubmit={submitCode}
          onBack={goBack}
          onClearError={() => setError(null)}
        />
      )}
      {step === StepEnum.DateOfBirth && (
        <DateOfBirthForm
          loading={loading}
          error={error}
          onSubmit={(values: { dateOfBirth: string }) =>
            submitCode({ code, dateOfBirth: values.dateOfBirth })
          }
          onBack={() => setStep(StepEnum.Username)}
          onClearError={() => setError(null)}
        />
      )}
    </>
  )

  return (
    <Layout>
      <Button back onClick={goBack}
	  trackingName="Login Code"
	  trackingEvent="Login code click: Tilbake">
        Tilbake
      </Button>
      <Wrapper>
        {(step === StepEnum.Username ||
          step === StepEnum.Code ||
          step === StepEnum.DateOfBirth) &&
          loginFlow}
        {step === StepEnum.Verification && verify}
      </Wrapper>
      {externalError && (
        <ErrorModal
          error={externalError}
          onClose={() => setExternalError(null)}
        />
      )}
    </Layout>
  )
}

export default LoginWithCode
