import { Form, Formik } from 'formik'
import { SigninSchema } from 'Modules/Login'
import { useState } from 'react'
import BateLoader from 'shared/components/BateLoader'

interface IProps {
  loading: boolean
  error: string | null | undefined
  vippsUrl: string
  clearError: () => void
  goToCreateUser: () => void
  goToForgotPassword: () => void
  onSubmit: ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => void
}

function BateLogin({
  loading,
  error,
  vippsUrl,
  clearError,
  goToCreateUser,
  goToForgotPassword,
  onSubmit,
}: IProps) {
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  return (
    <section className="main-section w-full">
      {loading && <BateLoader />}
      <div className="container container--small">
        <div className="box">
          <div className="box__icon">
            <img
              src={process.env.PUBLIC_URL + '/bate/gfx/innlogging_generelt.png'}
              alt=""
              className="box__icon__image box__icon__image--key"
            />
          </div>

          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              onSubmit(values)
            }}
          >
            {({
              errors,
              touched,
              submitForm,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form>
                <h1 className="font-clanot-ultra text-red">Logg inn</h1>
                <div className="form">
                  <label className="text-red font-clanot-black">
                    E-post eller mobilnummer
                  </label>
                  <input
                    name="username"
                    type="text"
                    autoFocus
                    className="input-text"
                    placeholder="Skriv inn her"
                    onChange={(e: any) => {
                      setFieldValue('username', e.target.value)
                      clearError()
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setFieldTouched('username')
                      }, 300)
                    }}
                  />
                  <label className="text-red font-clanot-black">Passord</label>
                  <div className="password">
                    <input
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      className="input-text"
                      placeholder="Minst 8 tegn"
                      onChange={(e: any) => {
                        setFieldValue('password', e.target.value)
                        clearError()
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setFieldTouched('password')
                        }, 300)
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <svg
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                          role="img"
                          fill="#dc151d"
                        >
                          <path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z"></path>
                        </svg>
                      ) : (
                        <svg
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                          role="img"
                          fill="#dc151d"
                        >
                          <path d="M12,7 C14.76,7 17,9.24 17,12 C17,12.65 16.87,13.26 16.64,13.83 L19.56,16.75 C21.07,15.49 22.26,13.86 22.99,12 C21.26,7.61 16.99,4.5 11.99,4.5 C10.59,4.5 9.25,4.75 8.01,5.2 L10.17,7.36 C10.74,7.13 11.35,7 12,7 Z M2,4.27 L4.28,6.55 L4.74,7.01 C3.08,8.3 1.78,10.02 1,12 C2.73,16.39 7,19.5 12,19.5 C13.55,19.5 15.03,19.2 16.38,18.66 L16.8,19.08 L19.73,22 L21,20.73 L3.27,3 L2,4.27 Z M7.53,9.8 L9.08,11.35 C9.03,11.56 9,11.78 9,12 C9,13.66 10.34,15 12,15 C12.22,15 12.44,14.97 12.65,14.92 L14.2,16.47 C13.53,16.8 12.79,17 12,17 C9.24,17 7,14.76 7,12 C7,11.21 7.2,10.47 7.53,9.8 L7.53,9.8 Z M11.84,9.02 L14.99,12.17 L15.01,12.01 C15.01,10.35 13.67,9.01 12.01,9.01 L11.84,9.02 Z"></path>{' '}
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <div className="forgotton-password">
                  <a onClick={goToForgotPassword} href="#">
                    Glemt passord?
                  </a>
                </div>
                {error && <p className="login-error text-red mb-1">{error}</p>}
                <div className="button-grid">
                  <button
                    type="submit"
                    className="btn btn--primary btn--icon group"
                  >
                    <div className="btn__label">
                      <img
                        src={process.env.PUBLIC_URL + '/bate/gfx/logg_inn.png'}
                        alt=""
                      />
                      <span>Logg inn</span>
                    </div>
                    <svg
                      className="btn__arrow"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                    </svg>
                  </button>
                  <button
                    onClick={goToCreateUser}
                    className="btn btn--secondary btn--icon group"
                  >
                    <div className="btn__label">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          '/bate/gfx/forfatter_publiserer.png'
                        }
                        alt=""
                      />
                      <span>Lag ny bruker</span>
                    </div>
                    <svg
                      className="btn__arrow"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                    </svg>
                  </button>
                  <p className="vipps-label-mobile font-clanot-black">
                    Logg inn / lag ny bruker med
                  </p>
                  <a
                    href={vippsUrl}
                    className="btn btn--secondary btn--vipps button-grid__wide group"
                  >
                    <div className="btn__label">
                      <span className="vipps-label-desktop">
                        Logg inn / lag ny bruker med
                      </span>
                      <img
                        src={
                          process.env.PUBLIC_URL + '/bate/gfx/logo-vipps.png'
                        }
                        alt="Vipps"
                      />
                    </div>
                    <svg
                      className="btn__arrow"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.707 13.707l5-5c0.391-0.39 0.391-1.024 0-1.414l-5-5c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l3.293 3.293h-9.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h9.586l-3.293 3.293c-0.195 0.195-0.293 0.451-0.293 0.707s0.098 0.512 0.293 0.707c0.391 0.391 1.024 0.391 1.414 0z"></path>
                    </svg>
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default BateLogin
